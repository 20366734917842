import { action, computed, makeObservable, observable, override } from 'mobx';

import { ToolsStoreBase } from '../../_base_';

export class MultimeterStore extends ToolsStoreBase {
  private _voltage = 0;
  // Измерительные щупы
  private _probes: number[] = [];

  public get voltage(): number {
    return this._voltage;
  }

  /**
   * Измрительные щупы
   */
  public get probes(): number[] {
    return this._probes;
  }

  constructor() {
    super();
    makeObservable<MultimeterStore, '_selected' | '_voltage' | '_probes'>(
      this,
      {
        _selected: override,
        selected: override,
        setSelected: override,
        toggleSelected: override,

        _voltage: observable,
        _probes: observable,
        voltage: computed,
        probes: computed,
        screenText: computed,
        connectProbe: action,
        disconnectProbe: action,
        disconnectAllProbes: action,
        setVoltage: action,
      }
    );
  }

  /**
   * Текст на экране мультиметра
   */
  public get screenText(): string {
    return this._voltage.toFixed(2);
  }

  public connectProbe(terminal: number): void {
    this._probes.push(terminal);
  }

  public disconnectProbe(terminal: number): void {
    for (let i = 0; i < this._probes.length; i += 1)
      if (this._probes[i] === terminal) {
        this._probes.splice(i, 1);
        return;
      }
  }

  public disconnectAllProbes(): void {
    this._probes.splice(0, this._probes.length);
  }

  /**
   * Установить текст на экране мультиметра
   */
  public setVoltage(voltage: number): void {
    this._voltage = voltage;
  }
}
