import { Layout } from 'antd';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { useRootData } from 'hooks';
import React, { FC, useEffect } from 'react';

import {
  AlreadyTakenLesson,
  AvailableLesson,
  LoadingSpin,
  UnavailableLesson,
} from '../components';
import { LessonExistenceType } from '../types';

import css from './index.module.css';

const LessonPage: FC = observer(() => {
  const { lessonId } = useParams<Record<string, string>>();

  const { doesLessonExist, isLessonTaken, fetchLessonScript } = useRootData(
    (store) => ({
      doesLessonExist: store.lesson.doesLessonExist,
      isLessonTaken: store.lesson.isLessonTaken,
      fetchLessonScript: store.lesson.fetchLessonScript,
    })
  );

  useEffect(() => {
    fetchLessonScript(lessonId);
  }, [fetchLessonScript, lessonId]);

  return (
    <Layout className={css.layout}>
      {doesLessonExist === LessonExistenceType.Unknown && <LoadingSpin />}
      {doesLessonExist === LessonExistenceType.Taken && <AlreadyTakenLesson />}
      {doesLessonExist === LessonExistenceType.Unavailable && (
        <UnavailableLesson />
      )}
      {doesLessonExist === LessonExistenceType.Available && <AvailableLesson />}
    </Layout>
  );
});

export default LessonPage;
