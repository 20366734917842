import { AdvancedDynamicTexture, Vector2WithInfo } from '@babylonjs/gui';
import {
  Color3,
  Observable,
  Scene,
  StandardMaterial,
  Texture,
} from '@babylonjs/core';
import { autorun } from 'mobx';

import { ISceneObject } from 'features/lesson-page/types';

import { ResultPanelBuildConfig } from '../types';
import { ResultPanelGUI } from '../gui';
import { ResultPanelModel } from '../model';
import { ResultPanelStore } from '../store';
import type { InfoPageContentType, StepsPageContentLineType } from '../store';

export class ResultPanelObject implements ISceneObject {
  private static WIDTH = 240;
  private static HEIGHT = 210;
  private static TEX_Q = 4;
  private static REAL_SCALE = 0.8 / 240;

  private _model: ResultPanelModel;
  private _texture: AdvancedDynamicTexture;
  private _gui: ResultPanelGUI;

  public onCloseButtonClick = new Observable<Vector2WithInfo>();
  public onRightButtonClick = new Observable<Vector2WithInfo>();
  public onLeftButtonClick = new Observable<Vector2WithInfo>();

  get model(): ResultPanelModel {
    return this._model;
  }

  constructor(scene: Scene, model: ResultPanelModel) {
    this._model = model;
    this._texture = new AdvancedDynamicTexture(
      'ResultPanel',
      ResultPanelObject.WIDTH * ResultPanelObject.TEX_Q,
      ResultPanelObject.HEIGHT * ResultPanelObject.TEX_Q,
      scene,
      false,
      Texture.TRILINEAR_SAMPLINGMODE,
      true
    );
    this._gui = new ResultPanelGUI(this._texture, ResultPanelObject.TEX_Q);

    // Настройка материала
    const modalMat = new StandardMaterial('TabletModal_material', scene);
    modalMat.backFaceCulling = false;
    modalMat.diffuseColor = Color3.Black();
    modalMat.specularColor = Color3.Black();
    modalMat.emissiveTexture = this._texture;
    modalMat.opacityTexture = this._texture;
    this.model.plane.material = modalMat;
    this._texture.attachToMesh(this._model.plane, true);

    // Перенаправление callback'ов
    this._gui.onCloseButtonClick = (e: Vector2WithInfo) => {
      this.onCloseButtonClick.notifyObservers(e);
    };
    this._gui.onRightButtonClick = (e: Vector2WithInfo) => {
      this.onRightButtonClick.notifyObservers(e);
    };
    this._gui.onLeftButtonClick = (e: Vector2WithInfo) => {
      this.onLeftButtonClick.notifyObservers(e);
    };
  }

  public static async setup(
    cfg: ResultPanelBuildConfig,
    scene: Scene
  ): Promise<ResultPanelObject> {
    const model = await ResultPanelModel.load(
      {
        height: ResultPanelObject.HEIGHT * ResultPanelObject.REAL_SCALE,
        width: ResultPanelObject.WIDTH * ResultPanelObject.REAL_SCALE,
      },
      scene
    );
    return new ResultPanelObject(scene, model);
  }

  /**
   * Подключить объект к его стору
   */
  public connectToStore(store: ResultPanelStore): void {
    autorun(() => {
      this.setVisibility(store.isVisible);
    });
    autorun(() => {
      this.openPage(store.pageNo);
    });
    autorun(() => {
      this.setInfoContent(store.infoPageContent);
    });
    autorun(() => {
      this.setStepsContent(store.stepsPageContent.lines);
    });
  }

  public openPage(pageNo: number): void {
    this._gui.openPage(pageNo);
  }

  public setInfoContent(content: InfoPageContentType): void {
    this._gui.setInfoContent(content);
  }

  public setStepsContent(content: StepsPageContentLineType[]): void {
    this._gui.setStepsContent(content);
  }

  public setVisibility(isVisible: boolean): void {
    this._model.setVisibility(isVisible);
  }
}
