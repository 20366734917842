/* eslint-disable no-param-reassign,
require-await,
@typescript-eslint/require-await,
@typescript-eslint/explicit-module-boundary-types,
@typescript-eslint/no-explicit-any */
import {
  AbstractMesh,
  ISceneLoaderAsyncResult,
  Quaternion,
  Scene,
  Vector3,
  WebXRCamera,
  WebXRDefaultExperience,
  WebXRInputSource,
} from '@babylonjs/core';
import {
  AdvancedDynamicTexture,
  Control,
  Rectangle,
  TextBlock,
  TextWrapping,
} from '@babylonjs/gui';
import { autorun } from 'mobx';
// eslint-disable-next-line import/no-cycle
import { LessonStoreInstance } from '../../store/index';
// eslint-disable-next-line import/no-cycle
import { Inventory } from './Inventory';
import { loadMesh } from '../../store/utils';

export type BeltMeshes = {
  [K in BeltKeys]: {
    mesh: ISceneLoaderAsyncResult;
    type: string;
    name: string;
    position: Vector3;
    binding?: BeltKeys;
  };
};
export type BeltKeys =
  | 'tablet'
  | 'belt'
  | 'leftGloves'
  | 'rightGloves'
  | 'leftHand'
  | 'multimeter'
  | 'multimeterLeft'
  | 'rightHand';

export type Probes = {
  probe: AbstractMesh;
  controller: WebXRInputSource;
};

export class Player {
  scene: Scene;
  camera: WebXRCamera;
  xr: WebXRDefaultExperience;
  tools!: {
    current: BeltKeys[] | null;
    previous: BeltKeys[] | null;
  };

  tablet: AbstractMesh;
  controllerRight: WebXRInputSource | undefined;
  controllerLeft: WebXRInputSource | undefined;
  beltMeshes!: BeltMeshes | undefined;
  inventory: Inventory | undefined;

  constructor(scene: Scene, xr: WebXRDefaultExperience, tablet: AbstractMesh) {
    this.scene = scene;
    this.camera = xr.input.xrCamera;
    this.xr = xr;
    this.tablet = tablet;
  }

  async init() {
    await this.loadXRmeshes();
    this.setBelt();
    this.getControllers(this.xr, () => {
      if (this.controllerRight && this.controllerLeft && this.beltMeshes) {
        this.inventory = new Inventory(
          this.scene,
          [
            this.beltMeshes.multimeter,
            this.beltMeshes.rightGloves,
            this.beltMeshes.tablet,
          ],
          [this.controllerRight, this.controllerLeft],
          this.beltMeshes
        );
        this.inventory.init();
      }
      this.setHandGUI();
    });
  }

  getControllers = async (xr: WebXRDefaultExperience, cb: () => void) => {
    if (xr.input.controllers.length >= 2) {
      this.controllerRight = xr.input.controllers.find((controller) => {
        return controller.motionController?.handedness === 'right';
      });
      this.controllerLeft = xr.input.controllers.find((controller) => {
        return controller.motionController?.handedness === 'left';
      });
      cb();
    } else {
      xr.input.onControllerAddedObservable.add((inputSource) => {
        inputSource.onMotionControllerInitObservable.add((motionController) => {
          const mesh = inputSource.grip;
          if (motionController.handedness === 'right') {
            this.controllerRight!.grip = mesh;
          } else {
            this.controllerLeft!.grip = mesh;
          }
          if (this.controllerRight && this.controllerLeft && this.beltMeshes) {
            cb();
          }
        });
      });
    }
  };

  async loadXRmeshes() {
    const [
      belt,
      rightHand,
      leftHand,
      rightGloves,
      leftGloves,
      multimeter,
      multimeterLeft,
      tablet,
    ] = await Promise.all([
      loadMesh(this.scene, 'Belt.gltf'),
      loadMesh(this.scene, 'HandRight.gltf'),
      loadMesh(this.scene, 'HandLeft.gltf'),
      loadMesh(this.scene, 'GlovedRightHand.gltf'),
      loadMesh(this.scene, 'GlovedLeftHand.gltf'),
      loadMesh(this.scene, 'HandWithMultimeter.gltf'),
      loadMesh(this.scene, 'LeftHandWithMultimeter.gltf'),
      loadMesh(this.scene, 'HoldTablet.gltf'),
    ]);
    this.setTabletGUI(tablet);
    this.beltMeshes = {
      rightHand: {
        mesh: rightHand,
        type: 'hands',
        name: 'rightHand',
        position: new Vector3(0, 0, 0),
        binding: 'leftHand',
      },
      leftHand: {
        mesh: leftHand,
        type: 'hands',
        name: 'leftHand',
        position: new Vector3(0, 0, 0),
        binding: 'rightHand',
      },
      rightGloves: {
        mesh: rightGloves,
        type: 'tool',
        name: 'rightGloves',
        position: new Vector3(-0.1, 0, 0.1),
        binding: 'leftGloves',
      },
      leftGloves: {
        mesh: leftGloves,
        type: 'basic',
        name: 'leftGloves',
        position: new Vector3(-0.1, 0, 0.1),
      },
      belt: {
        mesh: belt,
        type: 'belt',
        name: 'belt',
        position: new Vector3(0, 0, 0),
      },
      tablet: {
        mesh: tablet,
        type: 'tool',
        name: 'tablet',
        position: new Vector3(0, 0, 0),
      },
      multimeter: {
        mesh: multimeter,
        type: 'tool',
        name: 'multimeter',
        position: new Vector3(0, 0, 0),
        binding: 'multimeterLeft',
      },
      multimeterLeft: {
        mesh: multimeterLeft,
        type: 'basic',
        name: 'multimeterLeft',
        position: new Vector3(0, 0, 0),
      },
    };
  }

  async setBelt() {
    const belt = this.beltMeshes!.belt.mesh.meshes[0];
    this.scene.onBeforeRenderObservable.add(() => {
      const camPosition = this.xr.baseExperience.camera.globalPosition;
      const camRotation = this.xr.baseExperience.camera.rotation.z;
      belt.position.set(camPosition.x, camPosition.y - 0.5, camPosition.z);
      belt.rotation.z = camRotation;
    });
  }

  setHandGUI() {
    const advancedTexture = AdvancedDynamicTexture.CreateFullscreenUI(
      'ui1',
      true,
      this.scene
    );
    const label = new Rectangle('label');
    label.background = 'rgba(50,50,200,0.5)';
    label.height = '60px';
    label.width = '470px';
    label.cornerRadius = 20;
    label.thickness = 1;
    advancedTexture.addControl(label);
    label.linkWithMesh(this.controllerRight!.grip!);
    label.linkOffsetX = 200;
    label.linkOffsetY = 100;

    const text1 = new TextBlock();

    text1.alpha = 1;
    text1.color = 'white';
    text1.textWrapping = TextWrapping.Clip;
    text1.resizeToFit = true;
    text1.fontSize = '25px';
    text1.textHorizontalAlignment = Control.HORIZONTAL_ALIGNMENT_CENTER;
    label.addControl(text1);
    autorun(() => {
      text1.text = LessonStoreInstance.script.currentHint;
    });
  }

  setTabletGUI(tablet: ISceneLoaderAsyncResult) {
    tablet.meshes[1].isVisible = false;
    const tabletGlove = tablet.meshes[2];
    this.tablet.parent = tabletGlove;
    this.tablet.position = new Vector3(-0.145, 0.065, 0);
    this.tablet.rotationQuaternion = new Quaternion(-0.05, 0.1, 1, 0.13);
  }

  get multimeterProbes(): Probes[] {
    return [
      {
        probe: this.beltMeshes!.multimeter.mesh.meshes[4],
        controller: this.controllerRight!,
      },
      {
        probe: this.beltMeshes!.multimeterLeft.mesh.meshes[2],
        controller: this.controllerLeft!,
      },
    ];
  }
}
export type PlayerStoreType = typeof Player;
