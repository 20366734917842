import {
  AbstractMesh,
  AnimationGroup,
  LoadFileError,
  Scene,
} from '@babylonjs/core';

import { ISimpleModel } from '../../../common/types';
import { loadMesh } from '../../../common/utils';

import { KIPBuildConfig } from '../types';

export class KIPModel implements ISimpleModel {
  private _allMeshes: AbstractMesh[];

  private _root: AbstractMesh;

  private _main: AbstractMesh;
  private _backDoor: AbstractMesh;
  private _frontDoor: AbstractMesh;

  private _terminals: AbstractMesh[];
  private _measureTerminals: AbstractMesh[];
  private _powerTerminals: AbstractMesh[];

  private _openBackAnim: AnimationGroup;
  private _openFrontAnim: AnimationGroup;

  constructor(
    scene: Scene,
    kip_meshes: AbstractMesh[],
    kip_animations: AnimationGroup[]
  ) {
    this._allMeshes = kip_meshes;

    [this._root, this._backDoor, this._frontDoor] = kip_meshes;
    this._main = kip_meshes[kip_meshes.length - 1];
    [, , , this._openBackAnim, , , , this._openFrontAnim] = kip_animations;

    const comp = (a: AbstractMesh, b: AbstractMesh) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    };
    this._measureTerminals = kip_meshes
      .filter((mesh) => mesh.name.includes('MT'))
      .sort(comp);
    this._powerTerminals = kip_meshes
      .filter((mesh) => mesh.name.includes('PT'))
      .sort(comp);
    this._terminals = this._measureTerminals.concat(this._powerTerminals);
  }

  static async load(cfg: KIPBuildConfig, scene: Scene): Promise<KIPModel> {
    const res = await loadMesh(cfg.model, scene);
    if (
      !res ||
      res.meshes.length !== 21 ||
      !res.meshes.every((e) => e !== null) ||
      res.animationGroups.length !== 8
    ) {
      throw new LoadFileError('Unable to load mesh for KIPModel');
    }

    return new KIPModel(scene, res.meshes, res.animationGroups);
  }

  /**
   * Управлять глобальной видимостью объекта
   */
  setVisibility(isVisible: boolean): void {
    for (const m of this._allMeshes) m.isVisible = isVisible;
  }

  /**
   * Получить корень, за который можно привязывать модель
   */
  get root(): AbstractMesh {
    return this._root;
  }

  get main(): AbstractMesh {
    return this._main;
  }

  get backDoor(): AbstractMesh {
    return this._backDoor;
  }

  get frontDoor(): AbstractMesh {
    return this._frontDoor;
  }

  get terminals(): AbstractMesh[] {
    return this._terminals;
  }

  get measureTerminals(): AbstractMesh[] {
    return this._measureTerminals;
  }

  get powerTerminals(): AbstractMesh[] {
    return this._powerTerminals;
  }

  get openBackAnim(): AnimationGroup {
    return this._openBackAnim;
  }

  get openFrontAnim(): AnimationGroup {
    return this._openFrontAnim;
  }
}
