import { AdvancedDynamicTexture, Vector2WithInfo } from '@babylonjs/gui';

import { BabylonWindow } from 'features/lesson-page/scene-components/widgets';

import { WBodyAskAcceptVolt } from './body-ask-accept-volt';

type ClickCallback = (e: Vector2WithInfo) => void;

export class TabletModalWindowGUI {
  private _window: BabylonWindow;
  private _windowBody: WBodyAskAcceptVolt;

  public onCloseButtonClickCallback: ClickCallback | null = null;
  public onYesButtonClickCallback: ClickCallback | null = null;
  public onNoButtonClickCallback: ClickCallback | null = null;

  /**
   * Создание текстуры планшета
   * @param scene объект сцены
   * @param name имя, используемое для создания виджетов
   */
  constructor(texture: AdvancedDynamicTexture, scale: number) {
    this._window = new BabylonWindow({ close: true }, scale, 'window');
    this._window.titleText = 'Запись показаний';
    this._windowBody = new WBodyAskAcceptVolt(scale);
    this._window.addControl(this._windowBody);
    texture.addControl(this._window);

    // Перенаправления нужных callback'ов
    this._window.onCButtonCloseClickObservable.add((e: Vector2WithInfo) => {
      this.onCloseButtonClickCallback && this.onCloseButtonClickCallback(e);
    });
    this._windowBody.yesButton.onPointerClickObservable.add(
      (e: Vector2WithInfo) => {
        this.onYesButtonClickCallback && this.onYesButtonClickCallback(e);
      }
    );
    this._windowBody.noButton.onPointerClickObservable.add(
      (e: Vector2WithInfo) => {
        this.onNoButtonClickCallback && this.onNoButtonClickCallback(e);
      }
    );
  }
}
