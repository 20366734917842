import {
  AbstractMesh,
  LoadFileError,
  Material,
  Mesh,
  MeshBuilder,
  Scene,
  Space,
  Vector3,
} from '@babylonjs/core';

import { ISimpleModel } from '../../../common/types';
import { loadMesh } from '../../../common/utils';

import { MultimeterBuildConfig } from '../types';

export class MultimeterModel implements ISimpleModel {
  private _root: AbstractMesh;
  private _corpus: AbstractMesh;
  private _screen: AbstractMesh;
  private _modalPlane: Mesh;

  constructor(
    scene: Scene,
    root: AbstractMesh,
    corpus: AbstractMesh,
    screen: AbstractMesh
  ) {
    this._root = root;
    this._corpus = corpus;
    this._screen = screen;

    this._modalPlane = MeshBuilder.CreatePlane(
      'MultimeterModel_modal',
      {
        width: 0.04,
        height: 0.03,
      },
      scene
    );
    // выделить в отдельный метод и сделать более reusable
    this._modalPlane.isVisible = true;
    this._modalPlane.parent = this._screen;
    this._modalPlane.position.set(0.003, 0.103, 0);
    this._modalPlane.rotate(new Vector3(0, 1, 0), Math.PI / 2, Space.LOCAL);
    this._modalPlane.rotate(new Vector3(1, 0, 0), -Math.PI / 30, Space.LOCAL);
    this._modalPlane.billboardMode = Mesh.BILLBOARDMODE_NONE;
  }

  static async load(
    cfg: MultimeterBuildConfig,
    scene: Scene
  ): Promise<MultimeterModel> {
    const res = await loadMesh(cfg.model, scene);
    if (!res) {
      throw new LoadFileError('Unable to load mesh for Multimeter');
    }

    const [root, screen, , corpus] = res.meshes;
    return new MultimeterModel(scene, root, corpus, screen);
  }

  setModalPlaneMaterial(material: Material): void {
    this._modalPlane.material = material;
  }

  setVisibility(isVisible: boolean): void {
    const children = this._root.getChildMeshes();
    children.forEach((mesh) => {
      // eslint-disable-next-line no-param-reassign
      mesh.isVisible = isVisible;
    });
    this._modalPlane.isVisible = isVisible;
  }

  get plane(): AbstractMesh {
    return this._modalPlane;
  }

  get root(): AbstractMesh {
    return this._root;
  }

  get corpus(): AbstractMesh {
    return this._corpus;
  }
}
