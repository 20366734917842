import { DebugLayer, Engine, Scene, UniversalCamera } from '@babylonjs/core';

import { FullscreenPanelObject } from '../objects/fullscreen-panel';
import { SkyboxObject } from '../objects/skybox';
import { ToolsMultimeterObject, ToolsTabletObject } from '../objects/tools';
import {
  WorldKIPObject,
  WorldLandscapeObject,
  WorldResultPanelObject,
  WorldTabletModalWindowObject,
} from '../objects/world';

export class LessonScene {
  private _engine: Engine;
  private _scene: Scene;

  public camera: UniversalCamera | null = null;
  public fullscreenPanel: FullscreenPanelObject | null = null;
  public skybox: SkyboxObject | null = null;

  public world: {
    kip: WorldKIPObject | null;
    landscape: WorldLandscapeObject | null;
    resultPanel: WorldResultPanelObject | null;
    tabletModalWindow: WorldTabletModalWindowObject | null;
  };

  public tools: {
    multimeter: ToolsMultimeterObject | null;
    tablet: ToolsTabletObject | null;
  };

  public get babylonScene(): Scene {
    return this._scene;
  }

  public get debugLayer(): DebugLayer {
    return this._scene.debugLayer;
  }

  constructor(engine: Engine, scene: Scene) {
    this._engine = engine;
    this._scene = scene;

    this.world = {
      kip: null,
      landscape: null,
      resultPanel: null,
      tabletModalWindow: null,
    };

    this.tools = {
      multimeter: null,
      tablet: null,
    };
  }

  public runRenderLoop(): void {
    this._engine.runRenderLoop(() => {
      this._scene.render();
    });
  }

  public resize(): void {
    this._engine.resize();
  }
}
