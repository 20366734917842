import { makeAutoObservable } from 'mobx';

import {
  WorldKIPStore,
  WorldLandscapeStore,
  WorldResultPanelStore,
  WorldTabletModalWindowStore,
} from '../scene-components/objects/world';

export class WorldStore {
  private _kip = new WorldKIPStore();
  private _landscape = new WorldLandscapeStore();
  private _resultPanel = new WorldResultPanelStore();
  private _tabletModalWindow = new WorldTabletModalWindowStore();

  /**
   * KIP
   */
  public get kip(): WorldKIPStore {
    return this._kip;
  }

  /**
   * Landscape
   */
  public get landscape(): WorldLandscapeStore {
    return this._landscape;
  }

  /**
   * Result panel
   */
  public get resultPanel(): WorldResultPanelStore {
    return this._resultPanel;
  }

  /**
   * tablet modal window
   */
  public get tabletModalWindow(): WorldTabletModalWindowStore {
    return this._tabletModalWindow;
  }

  constructor() {
    makeAutoObservable(this);
  }
}
