import { AbstractMesh, LoadFileError, Scene, Vector3 } from '@babylonjs/core';

import { ISimpleModel } from '../../../common/types';
import { loadMesh } from '../../../common/utils';

import { TabletBuildConfig } from '../types';

export class TabletModel implements ISimpleModel {
  private _root: AbstractMesh;
  private _mainMesh: AbstractMesh;
  private _sheetMesh: AbstractMesh;

  constructor(
    scene: Scene,
    root: AbstractMesh,
    mainMesh: AbstractMesh,
    sheetMesh: AbstractMesh
  ) {
    this._root = root;
    this._mainMesh = mainMesh;
    this._sheetMesh = sheetMesh;

    this._root.scaling = new Vector3(1, 1, 1);
  }

  static async load(
    cfg: TabletBuildConfig,
    scene: Scene
  ): Promise<TabletModel> {
    const res = await loadMesh(cfg.model, scene);
    if (
      !res ||
      res.meshes.length !== 3 ||
      !res.meshes.every((e) => e !== null)
    ) {
      throw new LoadFileError('Unable to load mesh for TabletModel');
    }

    const [root, mainMesh, sheetMesh] = res.meshes;
    return new TabletModel(scene, root, mainMesh, sheetMesh);
  }

  /**
   * Управлять глобальной видимостью объекта
   */
  setVisibility(isVisible: boolean): void {
    this._mainMesh.isVisible = isVisible;
    this._sheetMesh.isVisible = isVisible;
  }

  /**
   * Получить корень, за который можно привязывать модель
   */
  get root(): AbstractMesh {
    return this._root;
  }

  get mainMesh(): AbstractMesh {
    return this._mainMesh;
  }

  get sheetMesh(): AbstractMesh {
    return this._sheetMesh;
  }
}
