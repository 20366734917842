import {
  Button,
  Container,
  Control,
  Measure,
  TextBlock,
  Vector2WithInfo,
} from '@babylonjs/gui';
import { Nullable, Observable } from '@babylonjs/core';

import { RectangleCorrect } from '../rectangle-correct';
import { drawRoundedRect } from '../../draw-utils/rounded-rect';

import { WindowCButtonClose } from './window-cbutton-close';

export type WindowCButtons = {
  close: boolean;
};

/**
 * Класс для создания окна
 */
export class BabylonWindow extends RectangleCorrect {
  /* Пропорции элементов окна */
  private _TITLE_HEIGHT = 20;
  private _BORDER_THICKNESS = 2;
  private _CORNER_RADIUS = 4;
  private _C_BUTTON_HEIGHT = 12;
  private _BETWEEN_C_BUTTONS = 1;
  private _TITLE_SIZE = 10;

  protected _body: Container;
  protected _titleText: TextBlock;
  protected _controlButtons: Button[] = [];

  private _titleHeight = 0;
  private _scale = 1;

  public onCButtonCloseClickObservable = new Observable<Vector2WithInfo>();

  /* Заголовок окна */
  public get titleText(): string {
    return this._titleText.text;
  }

  public set titleText(value: string) {
    this._titleText.text = value;
  }

  public getScale(): number {
    return this._scale;
  }

  /* Изменение масштаба интерфейса */
  public setScale(scale: number): void {
    this._scale = scale;
    this._titleHeight = this._TITLE_HEIGHT * scale;

    this.cornerRadius = this._CORNER_RADIUS * scale;
    this.thickness = this._BORDER_THICKNESS * scale;
    this._titleText.fontSize = this._TITLE_SIZE * scale;

    this._markAsDirty();
  }

  /**
   * Creates a new WindowBase
   * @param control_buttons какие кнопки управления будут включены
   * @param scale масштаб интерфейса
   * @param name defines control name
   */
  constructor(control_buttons?: WindowCButtons, scale = 1, name?: string) {
    super(name);
    this.background = '#eeed';
    this.color = 'white';

    // Создание тела окна
    this._body = new Container(`${name}_body`);
    this._body.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    this._body.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_CENTER;
    super.addControl(this._body);

    // Создание заголовка окна
    this._titleText = new TextBlock(`${name}_titleText`);
    this._titleText.text = 'Заголовок окна';
    this._titleText.fontFamily = 'sans-serif';
    this._titleText.color = '#222';
    this._titleText.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    this._titleText.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    this._titleText.textHorizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    this._titleText.textVerticalAlignment = Control.VERTICAL_ALIGNMENT_CENTER;
    super.addControl(this._titleText);

    // Создание нужных кнопок управления окном
    if (control_buttons && control_buttons.close) {
      const cbClose = new WindowCButtonClose(`${name}_cb_close`);
      cbClose.onPointerClickObservable.add((e: Vector2WithInfo) => {
        this.onCButtonCloseClickObservable.notifyObservers(e);
      });
      this._controlButtons.push(cbClose);
    }

    // Настройка всех кнопок управления окном
    for (let i = 0; i < this._controlButtons.length; i += 1) {
      const btn = this._controlButtons[i];
      btn.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
      btn.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_RIGHT;
      super.addControl(btn);
    }

    this.setScale(scale);
  }

  /**
   * Добавить виджет в окно. Для позиционирования доступно только тело окна
   */
  public addControl(control: Nullable<Control>): Container {
    // add to body of the window
    return this._body.addControl(control);
  }

  protected _getTypeName(): string {
    return this.constructor.name;
  }

  protected _localDraw(context: CanvasRenderingContext2D): void {
    context.save();
    super._localDraw(context);

    const round = this.cornerRadius;
    const box = this._currentMeasure;
    const d = this.thickness;

    // Отрисовка подложки для заголовка окна
    context.fillStyle = this.color;
    drawRoundedRect(
      context,
      new Measure(box.left, box.top, box.width, this._titleHeight + d / 2),
      {
        topLeft: round,
        topRight: round,
        bottomRight: 0,
        bottomLeft: 0,
      },
      d / 2
    );
    context.fill();

    context.restore();
  }

  protected _additionalProcessing(
    parentMeasure: Measure,
    context: CanvasRenderingContext2D
  ): void {
    super._additionalProcessing(parentMeasure, context);

    const box = this._currentMeasure;
    const scale = this._scale;
    const dyButton = ((this._TITLE_HEIGHT - this._C_BUTTON_HEIGHT) * scale) / 2;
    const dxButton = (this._C_BUTTON_HEIGHT + this._BETWEEN_C_BUTTONS) * scale;

    // Позиционирование кнопок
    for (let i = 0; i < this._controlButtons.length; i += 1) {
      const btn = this._controlButtons[i];
      btn.top = dyButton - this.thickness;
      btn.heightInPixels = this._C_BUTTON_HEIGHT * scale;
      btn.left = -dyButton + this.thickness - dxButton * i;
    }

    // Позиционирование текста
    const ttext = this._titleText;
    ttext.top = 0;
    ttext.left = dyButton - this.thickness;
    ttext.heightInPixels = this._TITLE_HEIGHT * scale - this.thickness * 2;
    ttext.widthInPixels =
      box.width - dyButton * 2 - dxButton * this._controlButtons.length;

    // Позиционирование тела окна
    this._body.top = this._titleHeight - this.thickness;
    this._body.heightInPixels = box.height - this._titleHeight - this.thickness;
  }

  /** Releases associated resources */
  public dispose(): void {
    super.dispose();

    this._body.dispose();

    for (const btn of this._controlButtons) btn.dispose();
  }
}
