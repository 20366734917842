import { makeObservable, override } from 'mobx';

import { ToolsStoreBase } from '../../_base_';

export class GlovesStore extends ToolsStoreBase {
  constructor() {
    super();
    makeObservable<GlovesStore, '_selected'>(this, {
      _selected: override,
      selected: override,
      setSelected: override,
      toggleSelected: override,
    });
  }
}
