import {
  AbstractMesh,
  ActionManager,
  AnimationGroup,
  Color3,
  ExecuteCodeAction,
  HighlightLayer,
  Mesh,
  Observable,
  Scene,
} from '@babylonjs/core';

/**
 * Класс для управления крышкой КИПа.
 * Умеет открывать/закрывать крышку при нажатии, а так же подсвечивать ее при наведении.
 */
export class KIPDoorController {
  private _scene: Scene;
  private _mesh: Mesh;
  private _openAnimation: AnimationGroup;
  private _highlightLayer: HighlightLayer;

  private _isOpen = false;
  private _isAnimating = false;
  private _targetIsOpen = false; // target task

  public onAnimationStart = new Observable<boolean>();
  public onAnimationDone = new Observable<boolean>();

  public onPointerClick = new Observable<void>();
  public onPointerOver = new Observable<void>(); // Курсор наведен на объект
  public onPointerOut = new Observable<void>(); // Курсор больше не наведен на объект

  public isOpen(): boolean {
    return this._isOpen;
  }

  /**
   * Создать класс управления крышкой КИПа
   */
  constructor(
    scene: Scene,
    highLightLayer: HighlightLayer,
    mesh: AbstractMesh,
    openAnimation: AnimationGroup
  ) {
    this._scene = scene;
    this._mesh = mesh as Mesh;
    this._openAnimation = openAnimation;
    this._highlightLayer = highLightLayer;

    // Создать менеджер действий
    if (!this._mesh.actionManager)
      this._mesh.actionManager = new ActionManager(scene);

    // При нажати на объект
    this._mesh.actionManager.registerAction(
      new ExecuteCodeAction(ActionManager.OnPickTrigger, () => {
        this.onPointerClick.notifyObservers();
      })
    );

    // При наведении курсора на объект
    this._mesh.actionManager.registerAction(
      new ExecuteCodeAction(ActionManager.OnPointerOverTrigger, () => {
        this.onPointerOver.notifyObservers();
      })
    );

    // При покидании объекта курсором
    this._mesh.actionManager.registerAction(
      new ExecuteCodeAction(ActionManager.OnPointerOutTrigger, () => {
        this.onPointerOut.notifyObservers();
      })
    );
  }

  /**
   * Включить/отключить подсветку крышки КИП.
   * @param enable Если true, то подсветка будет включена, иначе - отключена
   */
  public highlight(isEnable: boolean): void {
    if (isEnable) this._highlightLayer.addMesh(this._mesh, Color3.Green());
    else this._highlightLayer.removeMesh(this._mesh);
  }

  /**
   * Обновить состояние крышки КИПа, переведя его в состояние target.
   * Если нужно, воспроизвести анимацию.
   */
  private _updateDoorState() {
    if (this._targetIsOpen === this._isOpen) return;
    if (this._isAnimating) return;
    this._isAnimating = true;
    const targetIsOpenSave = this._targetIsOpen;

    const onAnimComplete = () => {
      this._isAnimating = false;
      this._isOpen = targetIsOpenSave;
      this.onAnimationDone.notifyObservers(this._isOpen);
      this._updateDoorState();
    };

    const beginAnimation = (from: number, to: number, callback: () => void) => {
      this._scene.beginAnimation(this._mesh, from, to, false, 1.0, callback);
    };

    this.onAnimationStart.notifyObservers(this._targetIsOpen);

    // Воспроизвести анимацию
    const anim = this._openAnimation;
    if (this._targetIsOpen) beginAnimation(anim.from, anim.to, onAnimComplete);
    else beginAnimation(anim.to, anim.from, onAnimComplete);
  }

  /**
   * Изменить состояние крышки КИП
   * @param targetIsOpen Состояние, в которое будет установлена крышка
   */
  public setDoorState(targetIsOpen: boolean): void {
    this._targetIsOpen = targetIsOpen;
    this._updateDoorState();
  }
}
