import { ISceneLoaderAsyncResult, Scene, SceneLoader } from '@babylonjs/core';

import { ModelBuildConfig } from './types';

export function loadMesh(
  cfg: ModelBuildConfig,
  scene: Scene
): Promise<ISceneLoaderAsyncResult> {
  return SceneLoader.ImportMeshAsync(null, cfg.root_url, cfg.filename, scene);
}
