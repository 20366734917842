import { AdvancedDynamicTexture } from '@babylonjs/gui';
import { Scene } from '@babylonjs/core';

import { FullscreenPanelBuildConfig } from '../types';
import { FullscreenPanelGUI } from './gui';
import { StepsPanelWrap } from '../steps-panel';

export class FullscreenPanelObject {
  private _texture: AdvancedDynamicTexture;
  private _gui: FullscreenPanelGUI;

  get stepsPanel(): StepsPanelWrap {
    return this._gui.stepsPanel;
  }

  constructor(scene: Scene) {
    this._texture = AdvancedDynamicTexture.CreateFullscreenUI('UI');
    this._gui = new FullscreenPanelGUI(this._texture, 1);
  }

  // eslint-disable-next-line @typescript-eslint/require-await, require-await
  public static async setup(
    cfg: FullscreenPanelBuildConfig,
    scene: Scene
  ): Promise<FullscreenPanelObject> {
    return new FullscreenPanelObject(scene);
  }

  public destroy(): void {
    this._texture.dispose();
  }
}
