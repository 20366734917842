import { action, makeAutoObservable } from 'mobx';

export class TabletModalWindowStore {
  private _isVisible = false;

  public get isVisible(): boolean {
    return this._isVisible;
  }

  constructor() {
    makeAutoObservable(this, {
      setVisibility: action,
    });
  }

  public setVisibility(isVisible: boolean): void {
    this._isVisible = isVisible;
  }
}
