import { action, autorun } from 'mobx';

// eslint-disable-next-line import/no-cycle
import { LessonStoreInstance as lessonStore } from 'features/lesson-page/store';

import { LessonScene } from '../scene';
import { SceneBuilderLogic } from '../types';

const setIsGlovesOn = action((v: boolean) => {
  lessonStore.sceneState.isGlovesOn = v;
});

const setIsTabletTaken = action((v: boolean) => {
  lessonStore.sceneState.isTabletTaken = v;
});

const setIsMultimeterTaken = action((v: boolean) => {
  lessonStore.sceneState.isMultimeterTaken = v;
});

const setIsTerminalsConnected = action((v: boolean) => {
  lessonStore.sceneState.isTerminalsConnected = v;
});

// TODO: Продумать как это вообще должно работать. Явно как-то не так
const setConnectedTerminals = action((v: number[]) => {
  lessonStore.sceneState.connectedTerminals = v.map(
    (x) => lessonStore.lessonScene?.world.kip?.model.terminals[x].name
  );
});

const setTabletEntriesCount = action((v: number) => {
  lessonStore.sceneState.tabletEntriesCount = v;
});

const setIsBackDoorOpen = action((v: boolean) => {
  lessonStore.sceneState.isBackDoorOpen = v;
});

const setIsFrontDoorOpen = action((v: boolean) => {
  lessonStore.sceneState.isFrontDoorOpen = v;
});

/**
 * Установка логики для sceneState
 */
export function setupLogicForSceneState(
  scene: LessonScene,
  cfg: SceneBuilderLogic
): void {
  autorun(() => {
    setIsGlovesOn(lessonStore.tools.gloves.selected);
    setIsTabletTaken(lessonStore.tools.tablet.selected);
    setIsMultimeterTaken(lessonStore.tools.multimeter.selected);
    setIsTerminalsConnected(lessonStore.tools.multimeter.probes.length === 2);

    setConnectedTerminals(lessonStore.tools.multimeter.probes.slice().sort());
    setTabletEntriesCount(lessonStore.tools.tablet.fullRecords.length);

    setIsBackDoorOpen(lessonStore.world.kip.backDoor.isOpen);
    setIsFrontDoorOpen(lessonStore.world.kip.frontDoor.isOpen);
  });
}
