import { makeAutoObservable } from 'mobx';

export class TabletRecord {
  public terminal1: string;
  public terminal2: string;
  public voltage: number;
  public errorAcceptable: boolean | undefined;

  constructor(
    terminal1: string,
    terminal2: string,
    voltage: number,
    errorAcceptable?: boolean | undefined
  ) {
    makeAutoObservable(this);
    this.terminal1 = terminal1;
    this.terminal2 = terminal2;
    this.voltage = voltage;
    this.errorAcceptable = errorAcceptable;
  }

  public get isFull(): boolean {
    return (
      this.terminal1 !== '' &&
      this.terminal2 !== '' &&
      this.errorAcceptable !== undefined
    );
  }
}
