import { AbstractMesh, Mesh, MeshBuilder, Scene } from '@babylonjs/core';

import { ISimpleModel } from '../types';

import { PlaneBuildConfig } from './types';

export class PlaneModel implements ISimpleModel {
  private _plane: Mesh;

  constructor(scene: Scene, width: number, height: number) {
    this._plane = MeshBuilder.CreatePlane(
      'PlaneModel_plane',
      { width, height },
      scene
    );
    this._plane.billboardMode = Mesh.BILLBOARDMODE_NONE;
  }

  // Для поддержания архитектуры, а так же потому что есть вероятность что в будущем
  // понадобится использовать await, решено оставить async и отключить линтер
  // eslint-disable-next-line @typescript-eslint/require-await, require-await
  static async load(cfg: PlaneBuildConfig, scene: Scene): Promise<PlaneModel> {
    return new PlaneModel(scene, cfg.width, cfg.height);
  }

  /**
   * Управлять глобальной видимостью объекта
   */
  setVisibility(isVisible: boolean): void {
    this._plane.isVisible = isVisible;
  }

  /**
   * Получить мешь лоскости
   */
  get plane(): AbstractMesh {
    return this._plane;
  }

  /**
   * Получить корень, за который можно привязывать модель
   */
  get root(): AbstractMesh {
    return this._plane;
  }
}
