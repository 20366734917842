import { LessonType } from 'api';
// eslint-disable-next-line import/no-cycle
import { LessonStoreInstance as lessonStore } from 'features/lesson-page/store';

import { LessonScene } from '../scene';
import { SceneBuilderLogic } from '../types';

/**
 * Установка логики для панели результатов
 */
export function setupLogicForWorldResultPanel(
  scene: LessonScene,
  cfg: SceneBuilderLogic
): void {
  if (!scene.world.resultPanel) return;
  const obj = scene.world.resultPanel;
  const objStore = lessonStore.world.resultPanel;

  // При перелистывании страничек
  obj.onRightButtonClick.add(() => {
    objStore.openPage(objStore.pageNo + 1);
  });
  obj.onLeftButtonClick.add(() => {
    objStore.openPage(objStore.pageNo - 1);
  });

  // При закрытии окна
  obj.onCloseButtonClick.add(() => {
    objStore.setVisibility(false);
  });

  if (lessonStore.lessonType === LessonType.Exam)
    obj.onCloseButtonClick.add(() => {
      lessonStore.isExamResultsPanelVisible = true;
    });
}
