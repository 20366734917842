import { makeAutoObservable } from 'mobx';

import {
  ToolsGlovesStore,
  ToolsMultimeterStore,
  ToolsTabletStore,
} from '../scene-components/objects/tools';

export class ToolsStore {
  private _gloves = new ToolsGlovesStore();
  private _tablet = new ToolsTabletStore();
  private _multimeter = new ToolsMultimeterStore();

  /**
   * Инструмент перчатки
   */
  public get gloves(): ToolsGlovesStore {
    return this._gloves;
  }

  /**
   * Инструмент планшет
   */
  public get tablet(): ToolsTabletStore {
    return this._tablet;
  }

  /**
   * Инструмент мультиметр
   */
  public get multimeter(): ToolsMultimeterStore {
    return this._multimeter;
  }

  constructor() {
    makeAutoObservable(this);
  }
}
