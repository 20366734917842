import { Scene } from '@babylonjs/core';
import { autorun } from 'mobx';

import { ISceneObject } from 'features/lesson-page/types';

import { LandscapeBuildConfig } from '../types';
import { LandscapeModel } from '../model';
import { LandscapeStore } from '../store';

export class LandscapeObject implements ISceneObject {
  private _model: LandscapeModel;

  /**
   * Модель Landscape'а
   */
  get model(): LandscapeModel {
    return this._model;
  }

  constructor(scene: Scene, model: LandscapeModel) {
    this._model = model;
  }

  /**
   * Скачать все модели и создать планшет
   */
  public static async setup(
    cfg: LandscapeBuildConfig,
    scene: Scene
  ): Promise<LandscapeObject> {
    const model = await LandscapeModel.load(cfg, scene);
    return new LandscapeObject(scene, model);
  }

  /**
   * Подключить объект к его стору
   */
  public connectToStore(store: LandscapeStore): void {
    autorun(() => {
      this.setVisibility(store.isVisible);
    });
  }

  /**
   * Изменить видимость Landscape
   */
  public setVisibility(isVisible: boolean): void {
    this._model.setVisibility(isVisible);
  }
}
