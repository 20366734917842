import {
  Engine,
  EngineOptions,
  HemisphericLight,
  ILoadingScreen,
  Scene,
  Space,
  UniversalCamera,
  Vector3,
} from '@babylonjs/core';

// eslint-disable-next-line import/no-cycle
import { LessonStore } from 'features/lesson-page/store';

import { FullscreenPanelObject } from '../objects/fullscreen-panel';
import { SkyboxObject } from '../objects/skybox';
import { ToolsMultimeterObject, ToolsTabletObject } from '../objects/tools';
import {
  WorldKIPObject,
  WorldLandscapeObject,
  WorldResultPanelObject,
  WorldTabletModalWindowObject,
} from '../objects/world';

import { LessonScene } from './scene';
import {
  SceneBuilderComponents,
  SceneBuilderLogic,
  SceneBuilderPlace,
} from './types';
import {
  setupLogicForFullscreenPanel,
  setupLogicForSceneState,
  setupLogicForToolsMultimeter,
  setupLogicForToolsTablet,
  setupLogicForWorldKIP,
  setupLogicForWorldResultPanel,
  setupLogicForWorldTabletModalWindow,
} from './logic';

function createLight(scene: Scene): HemisphericLight[] {
  const lights = [
    new HemisphericLight('light', new Vector3(1, 1, 0), scene),
    new HemisphericLight('light', new Vector3(-1, 1, 0), scene),
  ];
  lights[0].intensity = 0.7;
  lights[1].intensity = 0.7;
  return lights;
}

function createCamera(
  scene: Scene,
  canvasElement: HTMLCanvasElement
): UniversalCamera {
  const camera = new UniversalCamera(
    'UniversalCamera',
    new Vector3(-5, 0, 0),
    scene
  );
  camera.minZ = 0.05;
  camera.fov = 0.4;
  camera.ellipsoid.set(1, 0.8, 1);

  // WASD
  camera.keysUp.push(87);
  camera.keysLeft.push(65);
  camera.keysDown.push(83);
  camera.keysRight.push(68);

  camera.checkCollisions = true;
  camera.applyGravity = true;

  camera.inertia = 0.6;
  camera.speed = 1;
  camera.setTarget(new Vector3(0, 0.8, 0));

  camera.attachControl(canvasElement, true);

  return camera;
}

export class LessonSceneBuilder {
  private _canvas: HTMLCanvasElement;
  private _engine: Engine;
  private _scene: LessonScene | null = null;

  public get scene(): LessonScene {
    if (!this._scene) throw new TypeError('Scene is null. Call .load() before');
    return this._scene;
  }

  constructor(
    canvas: HTMLCanvasElement,
    antialias?: boolean | undefined,
    options?: EngineOptions | undefined,
    adaptToDeviceRatio?: boolean | undefined
  ) {
    this._canvas = canvas;
    this._engine = new Engine(canvas, antialias, options, adaptToDeviceRatio);
  }

  public setLoadingScreen(screen: ILoadingScreen): void {
    this._engine.loadingScreen = screen;
  }

  public displayLoadingUI(): void {
    this._engine.displayLoadingUI();
  }

  public hideLoadingUI(): void {
    this._engine.hideLoadingUI();
  }

  /**
   * Загрузить все необходимые данные для сцены
   */
  public async load(cfg: SceneBuilderComponents): Promise<void> {
    // Создание сцены
    const babylonScene = new Scene(this._engine);
    const scene = new LessonScene(this._engine, babylonScene);
    this._scene = scene;

    const emptyPromise = new Promise<null>((resolve, reject) => resolve(null));
    const sceneComponents = Promise.all([
      cfg.fullscreenPanel
        ? FullscreenPanelObject.setup(cfg.fullscreenPanel, babylonScene)
        : emptyPromise,
      cfg.skybox ? SkyboxObject.setup(cfg.skybox, babylonScene) : emptyPromise,

      cfg.tools.tablet
        ? ToolsTabletObject.setup(cfg.tools.tablet, babylonScene)
        : emptyPromise,
      cfg.tools.multimeter
        ? ToolsMultimeterObject.setup(cfg.tools.multimeter, babylonScene)
        : emptyPromise,

      cfg.world.KIP
        ? WorldKIPObject.setup(cfg.world.KIP, babylonScene)
        : emptyPromise,
      cfg.world.landscape
        ? WorldLandscapeObject.setup(cfg.world.landscape, babylonScene)
        : emptyPromise,
      cfg.world.resultPanel
        ? WorldResultPanelObject.setup(cfg.world.resultPanel, babylonScene)
        : emptyPromise,
      cfg.world.tabletModalWindow
        ? WorldTabletModalWindowObject.setup(
            cfg.world.tabletModalWindow,
            babylonScene
          )
        : emptyPromise,
    ]);

    createLight(babylonScene);
    scene.camera = createCamera(babylonScene, this._canvas);

    [
      scene.fullscreenPanel,
      scene.skybox,

      scene.tools.tablet,
      scene.tools.multimeter,

      scene.world.kip,
      scene.world.landscape,
      scene.world.resultPanel,
      scene.world.tabletModalWindow,
    ] = await sceneComponents;
  }

  /**
   * Расположить элементы сцены, привязать элемены друг к другу
   */
  public place(cfg: SceneBuilderPlace): void {
    const { babylonScene, camera, tools, world } = this.scene;

    babylonScene.gravity = new Vector3(0, -0.15, 0);
    babylonScene.collisionsEnabled = true;

    if (tools.tablet) {
      // attach tablet to camera
      const { root } = tools.tablet.model;
      root.parent = camera;
      root.rotate(new Vector3(0, 0, 1), Math.PI, Space.LOCAL);
      root.rotate(new Vector3(1, 0, 0), Math.PI / 2, Space.LOCAL);
      root.position.set(0.2, 0, 0.95);
    }

    if (tools.multimeter) {
      const { root } = tools.multimeter.model;
      root.parent = camera;
      root.position.set(0.07, -0.07, 0.5);
      root.rotate(new Vector3(0, 1, 0), (3 * Math.PI) / 2, Space.LOCAL);
    }

    if (world.kip) {
      const { model } = world.kip;
      model.main.checkCollisions = true;
      model.root.position = new Vector3(0, 0, 0);
    }

    if (world.landscape) {
      const { model } = world.landscape;
      model.main.checkCollisions = true;
    }

    if (world.resultPanel) {
      const { root } = world.resultPanel.model;
      root.rotate(new Vector3(0, 1, 0), Math.PI / 2, Space.LOCAL);
      root.position.set(0, 0.7, -0.5);
    }

    if (world.tabletModalWindow && tools.tablet) {
      const { root } = world.tabletModalWindow.model;
      root.parent = tools.tablet.model.sheetMesh;
      root.position.set(0, 0.001, 0);
      root.rotate(new Vector3(1, 0, 0), Math.PI / 2, Space.LOCAL);
    }
  }

  public connectToStore(store: LessonStore): void {
    const { scene } = this;

    scene.fullscreenPanel?.stepsPanel.connectToStore(
      store.fullscreenPanel.stepsPanel
    );

    scene.tools.tablet?.connectToStore(store.tools.tablet);
    scene.tools.multimeter?.connectToStore(store.tools.multimeter);

    scene.world.kip?.connectToStore(store.world.kip);
    scene.world.landscape?.connectToStore(store.world.landscape);
    scene.world.resultPanel?.connectToStore(store.world.resultPanel);
    scene.world.tabletModalWindow?.connectToStore(
      store.world.tabletModalWindow
    );
  }

  /**
   * Задать логику работы сцены. Установить реакции на события разных элементов
   */
  public setupLogic(cfg: SceneBuilderLogic): void {
    const { scene } = this;

    setupLogicForFullscreenPanel(scene, cfg);
    setupLogicForSceneState(scene, cfg);

    setupLogicForToolsMultimeter(scene, cfg);
    setupLogicForToolsTablet(scene, cfg);
    setupLogicForWorldTabletModalWindow(scene, cfg);

    setupLogicForWorldKIP(scene, cfg);
    setupLogicForWorldResultPanel(scene, cfg);
  }
}
