import { Scene } from '@babylonjs/core';

import { SkyboxBuildConfig } from '../types';
import { SkyboxModel } from '../model';

export class SkyboxObject {
  private _model: SkyboxModel;

  /**
   * Модель Skybox'а
   */
  get model(): SkyboxModel {
    return this._model;
  }

  constructor(scene: Scene, model: SkyboxModel) {
    this._model = model;
  }

  /**
   * Скачать все модели и создать Skybox
   */
  public static async setup(
    cfg: SkyboxBuildConfig,
    scene: Scene
  ): Promise<SkyboxObject> {
    const model = await SkyboxModel.load(cfg, scene);
    return new SkyboxObject(scene, model);
  }
}
