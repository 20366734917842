import { action, makeAutoObservable } from 'mobx';

export class KIPTerminalsStore {
  private _highlighted: boolean[];

  /**
   * Подсвечены ли клемы
   */
  public get highlighted(): boolean[] {
    return this._highlighted;
  }

  constructor(len: number) {
    this._highlighted = Array.from({ length: len }, () => false);
    makeAutoObservable(this, {
      setHighlightedAll: action,
      setHighlighted: action,
    });
  }

  /**
   * Количество клем
   */
  public get length(): number {
    return this._highlighted.length;
  }

  /**
   * Установить параметр highlighted у всех клемм
   */
  public setHighlightedAll(value: boolean): void {
    for (let i = 0; i < this._highlighted.length; i += 1)
      this._highlighted[i] = value;
  }

  /**
   * Установить параметр highlighted у клеммы index
   */
  public setHighlighted(index: number, value: boolean): void {
    this._highlighted[index] = value;
  }
}
