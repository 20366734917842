import { action, computed, makeObservable, observable, override } from 'mobx';

import { TabletRecord } from './types';
import { ToolsStoreBase } from '../../_base_';

export { TabletRecord } from './types';

export class TabletStore extends ToolsStoreBase {
  private _records: TabletRecord[] = [];
  private _isEnabled = false;

  /**
   * Получить все записи планшета
   */
  public get records(): TabletRecord[] {
    return this._records;
  }

  /**
   * Показывать ли модальное окно
   */
  public get isEnabled(): boolean {
    return this._isEnabled;
  }

  constructor() {
    super();
    makeObservable<TabletStore, '_selected' | '_records' | '_isEnabled'>(this, {
      _selected: override,
      selected: override,
      setSelected: override,
      toggleSelected: override,

      _records: observable,
      _isEnabled: observable,
      records: computed,
      fullRecords: computed,
      addRecord: action,
      setErrorAcceptableInLastRecord: action,
      removeLastRecord: action,
      setEnabled: action,
    });
  }

  /**
   * Получить только полностью заполненные записи планшета
   */
  public get fullRecords(): TabletRecord[] {
    return this._records.filter((x) => x.isFull);
  }

  /**
   * Добавить запись в конец планшета
   */
  public addRecord(
    terminal1: string,
    terminal2: string,
    voltage: number,
    errorAcceptable?: boolean | undefined
  ): void {
    this._records.push(
      new TabletRecord(terminal1, terminal2, voltage, errorAcceptable)
    );
  }

  /**
   * Установить значение ErrorAcceptable в последней строчке планшета
   */
  public setErrorAcceptableInLastRecord(errorAcceptable: boolean): void {
    this._records[this._records.length - 1].errorAcceptable = errorAcceptable;
  }

  /**
   * Удалить последнюю строчку из планшета
   */
  public removeLastRecord(): void {
    this._records.pop();
  }

  public setEnabled(value: boolean): void {
    this._isEnabled = value;
  }
}
