import { action, computed, makeObservable, observable } from 'mobx';

export class ToolsStoreBase {
  private _selected = false;

  /**
   * Выбрал ли предмет
   */
  get selected(): boolean {
    return this._selected;
  }

  constructor() {
    makeObservable<ToolsStoreBase, '_selected'>(this, {
      _selected: observable,
      selected: computed,
      setSelected: action,
      toggleSelected: action,
    });
  }

  /**
   * Установить выбран ли предмет
   */
  public setSelected(selected: boolean): void {
    this._selected = selected;
  }

  /**
   * Изменить состояние selected на противоположное
   */
  public toggleSelected(): void {
    this._selected = !this._selected;
  }
}
