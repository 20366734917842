import {
  AdvancedDynamicTexture,
  Button,
  Control,
  TextBlock,
  Vector2WithInfo,
} from '@babylonjs/gui';

import { GridTable } from 'features/lesson-page/scene-components/widgets';
import { TabletRecord } from '../store';

export class TabletSheetGUI {
  private TITLE_FONT_SIZE = 10;
  private TITLE_TOP_PADDING = 20;

  private TABLE_FONT_SIZE = 7;
  private TABLE_TOP_PADDING = 8;
  private TABLE_HEIGHT = 0.72;

  private BUTTON_FONT_SIZE = 10;

  private BUTTON_MARGIN = 8;
  private BUTTON_HEIGHT = 29;
  private ROWS_NUMBER = 11;

  private COLUMN_SIZES = [8, 17, 17, 22, 24];

  private _addButton: Button;

  public titleBlocks: TextBlock[] = [];

  public columnNumber: TextBlock[] = [];
  public columnTerm1: TextBlock[] = [];
  public columnTerm2: TextBlock[] = [];
  public columnVoltage: TextBlock[] = [];
  public columnAccept: TextBlock[] = [];

  public onAddButtonClickCallback: ((e: Vector2WithInfo) => void) | null = null;

  public get isEnabled(): boolean {
    return this._addButton.isEnabled;
  }

  public set isEnabled(value: boolean) {
    this._addButton.isEnabled = value;
  }

  /**
   * Создание текстуры планшета
   */
  constructor(texture: AdvancedDynamicTexture, scale: number) {
    this._setupTitleText(texture, scale);
    this._setupTable(texture, scale);
    this._addButton = this._setupAddButton(texture, scale);
  }

  /**
   * Создание и настройка заголовка таблицы
   */
  // eslint-disable-next-line class-methods-use-this
  private _setupTitleText(
    texture: AdvancedDynamicTexture,
    scale: number
  ): TextBlock {
    const titleText = new TextBlock('titleText');
    titleText.color = '#222';
    titleText.fontSize = this.TITLE_FONT_SIZE * scale;
    titleText.fontFamily = 'sans-serif';
    titleText.fontWeight = 'bold';
    titleText.text = 'Ведомость замера потенциалов';
    titleText.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    titleText.heightInPixels = this.TITLE_FONT_SIZE * scale;
    titleText.top = this.TITLE_TOP_PADDING * scale;

    texture.addControl(titleText);
    return titleText;
  }

  /**
   * Создание и настройка таблицы
   */
  private _setupTable(
    texture: AdvancedDynamicTexture,
    scale: number
  ): GridTable {
    const sz = texture.getSize();

    const table = new GridTable('Table');
    table.lineWidth = scale;
    table.strokeStyle = '#555';
    table.showTopLine = false;
    table.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    table.widthInPixels = sz.width - this.BUTTON_MARGIN * 2 * scale;
    table.top =
      (this.TITLE_TOP_PADDING + this.TITLE_FONT_SIZE + this.TABLE_TOP_PADDING) *
      scale;
    table.height = this.TABLE_HEIGHT;

    // title line
    table.addRowDefinition(4, false);

    for (let i = 0; i < this.COLUMN_SIZES.length; i += 1) {
      table.addColumnDefinition(this.COLUMN_SIZES[i], false);

      const titleBlock = new TextBlock();
      titleBlock.color = 'black';
      titleBlock.textWrapping = true;
      titleBlock.fontFamily = 'sans-serif';
      titleBlock.fontWeight = 'bold';
      titleBlock.fontSize = this.TABLE_FONT_SIZE * scale;
      table.addControl(titleBlock, 0, i);
      this.titleBlocks[i] = titleBlock;
    }

    this.titleBlocks[0].text = '№';
    this.titleBlocks[1].text = 'Клемма 1';
    this.titleBlocks[2].text = 'Клемма 2';
    this.titleBlocks[3].text = 'Напряжение';
    this.titleBlocks[4].text = 'В пределах\nдопустимого';

    const textBoxBuilder = (column: Control[], x: number, y: number) => {
      const res = new TextBlock();
      res.color = 'black';
      res.fontFamily = 'sans-serif';
      res.fontSize = this.TABLE_FONT_SIZE * scale;
      table.addControl(res, x, y);
      column.push(res);
      return res;
    };

    for (let i = 0; i < this.ROWS_NUMBER; i += 1) {
      table.addRowDefinition(3, false);

      textBoxBuilder(this.columnNumber, i + 1, 0);
      textBoxBuilder(this.columnTerm1, i + 1, 1);
      textBoxBuilder(this.columnTerm2, i + 1, 2);
      textBoxBuilder(this.columnVoltage, i + 1, 3);
      textBoxBuilder(this.columnAccept, i + 1, 4);
    }

    texture.addControl(table);
    return table;
  }

  /**
   * Создание и настройка кнопки "Записать значения"
   */
  private _setupAddButton(
    texture: AdvancedDynamicTexture,
    scale: number
  ): Button {
    const button = Button.CreateSimpleButton('AddButton', 'Записать значения');
    button.color = 'black';
    button.background = '#ffefdb';
    button.fontSize = this.BUTTON_FONT_SIZE * scale;
    button.cornerRadius = 5;
    button.verticalAlignment = Control.VERTICAL_ALIGNMENT_BOTTOM;
    button.heightInPixels = (this.BUTTON_HEIGHT + this.BUTTON_MARGIN) * scale;
    button.paddingLeftInPixels = this.BUTTON_MARGIN * scale;
    button.paddingRightInPixels = this.BUTTON_MARGIN * scale;
    button.paddingBottomInPixels = this.BUTTON_MARGIN * scale;
    button.onPointerClickObservable.add((e: Vector2WithInfo) => {
      this.onAddButtonClickCallback && this.onAddButtonClickCallback(e);
    });
    texture.addControl(button);
    return button;
  }

  /**
   * Обновить все записи в таблице
   */
  public setTabletContent(records: TabletRecord[]): void {
    const rowsToProcess = Math.min(records.length, this.ROWS_NUMBER);

    for (let i = 0; i < rowsToProcess; i += 1) {
      this.columnNumber[i].text = `${i + 1}`;
      this.columnTerm1[i].text = records[i].terminal1;
      this.columnTerm2[i].text = records[i].terminal2;
      this.columnVoltage[i].text = records[i].voltage.toFixed(2);

      if (records[i].errorAcceptable === undefined)
        this.columnAccept[i].text = '';
      else if (records[i].errorAcceptable) this.columnAccept[i].text = 'допуск';
      else this.columnAccept[i].text = 'не допуск';
    }

    for (let i = rowsToProcess; i < this.ROWS_NUMBER; i += 1) {
      this.columnNumber[i].text = '';
      this.columnTerm1[i].text = '';
      this.columnTerm2[i].text = '';
      this.columnVoltage[i].text = '';
      this.columnAccept[i].text = '';
    }
  }
}
