import { Layout } from 'antd';
import { LessonType } from 'api';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { useRootData } from 'hooks';
import React, { FC, useEffect } from 'react';

import { SceneLoadingProgressType } from 'features/lesson-page/types';

import {
  FinishButton,
  InitialModal,
  Scene,
  ToolBar,
  UserHints,
} from '../components';

import css from './index.module.css';

const AvailableLesson: FC = observer(() => {
  const { lessonId } = useParams<Record<string, string>>();

  const {
    lessonType,
    isLessonStarted,
    sceneLoadingProgress,
    subscribeByLearner,
    unsubscribeByLearner,
  } = useRootData((store) => ({
    lessonType: store.lesson.lessonType,
    isLessonStarted: !!store.lesson.lessonStartTime,
    isLessonFinished: !!store.lesson.lessonFinishTime,
    isExamResultsPanelVisible: !!store.lesson.isExamResultsPanelVisible,
    sceneLoadingProgress: store.lesson.sceneLoadingScreenProgress,
    subscribeByLearner: store.lesson.subscribeByLearner,
    unsubscribeByLearner: store.lesson.unsubscribeByLearner,
    unfulfilledRequiredStates: store.lesson.unfulfilledRequiredStates,
  }));

  useEffect(() => {
    if (lessonId && lessonType) {
      subscribeByLearner(lessonId, lessonType);
    }
    return () => unsubscribeByLearner();
  }, [subscribeByLearner, unsubscribeByLearner, lessonId, lessonType]);

  return (
    <Layout className={css.layout}>
      <InitialModal />
      {isLessonStarted && (
        <>
          <Scene />
          {sceneLoadingProgress === SceneLoadingProgressType.Loaded && (
            <>
              {lessonType !== LessonType.Exam && <UserHints />}
              <FinishButton />
              <ToolBar />
            </>
          )}
        </>
      )}
    </Layout>
  );
});

export default AvailableLesson;
