import {
  AdvancedDynamicTexture,
  Control,
  Vector2WithInfo,
} from '@babylonjs/gui';

import { BabylonWindow } from 'features/lesson-page/scene-components/widgets';

import { ArrowButton, ArrowButtonType } from './arrow-button';
import { InfoPageContentType, StepsPageContentLineType } from '../store';
import { WBodyInfoPage } from './body-info-page';
import { WBodyStepsPage } from './body-steps-page';

type ClickCallback = (e: Vector2WithInfo) => void;

export class ResultPanelGUI {
  private _ARROW_SIZE = 18;
  private _ARROW_MARGIN = 3;

  private _window: BabylonWindow;

  private _bodyInfoPage: WBodyInfoPage;
  private _bodyStepsPage: WBodyStepsPage;
  private _pageNo = 0;
  private _pages: Control[] = [];

  public onCloseButtonClick: ClickCallback | null = null;
  public onRightButtonClick: ClickCallback | null = null;
  public onLeftButtonClick: ClickCallback | null = null;

  constructor(texture: AdvancedDynamicTexture, scale: number) {
    const sz = texture.getSize();

    this._window = new BabylonWindow({ close: true }, scale, 'window');
    this._window.titleText = 'Результаты урока';

    this._bodyInfoPage = new WBodyInfoPage(scale);
    this._bodyStepsPage = new WBodyStepsPage(scale);
    this._pages = [this._bodyInfoPage, this._bodyStepsPage];
    for (const p of this._pages) p.isVisible = false;
    this._pages[0].isVisible = true;

    this._window.addControl(this._bodyInfoPage);
    this._window.addControl(this._bodyStepsPage);
    this._window.widthInPixels =
      sz.width - (this._ARROW_SIZE + this._ARROW_MARGIN * 2) * 2 * scale;
    texture.addControl(this._window);

    const rArrow = new ArrowButton(ArrowButtonType.RIGHT);
    rArrow.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_RIGHT;
    rArrow.verticalAlignment = Control.VERTICAL_ALIGNMENT_CENTER;
    rArrow.widthInPixels = this._ARROW_SIZE * scale;
    texture.addControl(rArrow);

    const lArrow = new ArrowButton(ArrowButtonType.LEFT);
    lArrow.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    lArrow.verticalAlignment = Control.VERTICAL_ALIGNMENT_CENTER;
    lArrow.widthInPixels = this._ARROW_SIZE * scale;
    texture.addControl(lArrow);

    // Перенаправление callback'ов
    this._window.onCButtonCloseClickObservable.add((e: Vector2WithInfo) => {
      this.onCloseButtonClick && this.onCloseButtonClick(e);
    });
    rArrow.onPointerClickObservable.add((e: Vector2WithInfo) => {
      this.onRightButtonClick && this.onRightButtonClick(e);
    });
    lArrow.onPointerClickObservable.add((e: Vector2WithInfo) => {
      this.onLeftButtonClick && this.onLeftButtonClick(e);
    });
  }

  getPageNo(): number {
    return this._pageNo;
  }

  openPage(pageNo: number): void {
    if (pageNo < 0 || pageNo >= this._pages.length) return;
    if (this._pageNo === pageNo) return;
    this._pageNo = pageNo;

    for (const p of this._pages) p.isVisible = false;
    this._pages[pageNo].isVisible = true;
  }

  setInfoContent(content: InfoPageContentType): void {
    this._bodyInfoPage.setContent(content);
  }

  setStepsContent(content: StepsPageContentLineType[]): void {
    this._bodyStepsPage.setContent(content);
  }
}
