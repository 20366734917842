import {
  AbstractMesh,
  ActionManager,
  Color3,
  ExecuteCodeAction,
  HighlightLayer,
  Mesh,
  MeshBuilder,
  Scene,
  Vector3,
  WebXRInputSource,
} from '@babylonjs/core';
// eslint-disable-next-line import/no-cycle
import { LessonStoreInstance as lessonStore } from '../../store';
// eslint-disable-next-line import/no-cycle
import { BeltKeys, BeltMeshes } from './player';

type InventoryItems = BeltMeshes;
type Item = InventoryItems[BeltKeys];

type Slot = {
  isEmpty: boolean;
  item: Item;
  slotMesh: AbstractMesh;
};

export class Inventory {
  private scene: Scene;
  private items: Item[];
  private allItems: InventoryItems;
  private controllerRight: WebXRInputSource;
  private controllerLeft: WebXRInputSource;
  private rootMeshRight!: AbstractMesh;
  private rootMeshLeft!: AbstractMesh;
  private slots: Slot[];
  private handsIntersected: null | Slot;
  private highlight!: HighlightLayer;
  private hands: Item[];

  constructor(
    scene: Scene,
    items: Item[],
    controllers: WebXRInputSource[],
    allItems: InventoryItems
  ) {
    this.scene = scene;
    this.items = items;
    this.slots = [];
    this.hands = [];
    this.allItems = allItems;
    this.handsIntersected = null;
    [this.controllerRight, this.controllerLeft] = controllers;
    this._setRootMesh();
  }

  private _setRootMesh(): void {
    const rootMeshRight = new Mesh('right', this.scene);
    const rootMeshLeft = new Mesh('left', this.scene);
    rootMeshRight.isPickable = false;
    rootMeshLeft.isPickable = false;
    rootMeshRight.parent =
      this.controllerRight.grip || this.controllerRight.pointer;
    rootMeshRight.rotation = new Vector3(
      Math.PI / 0.75,
      Math.PI / 1.2,
      Math.PI / 5
    );
    rootMeshLeft.parent =
      this.controllerLeft.grip || this.controllerLeft.pointer;
    rootMeshLeft.rotation = new Vector3(
      Math.PI / 0.75,
      Math.PI / 1.2,
      Math.PI / 5
    );
    this.rootMeshRight = rootMeshRight;
    this.rootMeshLeft = rootMeshLeft;
  }

  init(): void {
    this.createSlots(this.allItems.belt.mesh.meshes[0], [
      new Vector3(-0.15, 0, 0.2),
      new Vector3(-0.15, 0, -0.2),
      new Vector3(0, 0, 0.4),
      new Vector3(0, 0, -0.4),
      new Vector3(-0.2, 0, 0.6),
    ]);
    this.setHands(this.allItems.rightHand, this.allItems.leftHand);
    this.addEvtChangeHands(this.controllerRight, this.slots);
    this.addEvtChangeHands(this.controllerLeft, this.slots);
    this.setController();
    this.allItems.multimeter.mesh.meshes[8].isVisible = false;
    this.allItems.tablet.mesh.meshes[2].isVisible = false;
    lessonStore.lessonScene?.fullscreenPanel?.destroy();
  }

  createSlots(slotsParent: AbstractMesh, slotsPosition: Vector3[]): void {
    this.items.forEach((item, i): void => {
      const mesh = item.mesh.meshes[0];
      const MeshSize = mesh.getHierarchyBoundingVectors();
      const size = {
        x: MeshSize.max.x - MeshSize.min.x,
        y: MeshSize.max.y - MeshSize.min.y,
        z: MeshSize.max.z - MeshSize.min.z,
      };
      const slot = MeshBuilder.CreateBox(
        'box',
        { size: 1, width: size.x, height: size.y, depth: size.z },
        this.scene
      );
      slot.position = slotsPosition[i];
      slot.visibility = 0;
      mesh.parent = slot;
      slot.parent = slotsParent;
      this.slots.push({
        slotMesh: slot,
        isEmpty: false,
        item,
      });
    });
    // TODO
    this.slots[2].slotMesh.rotation.x = 1.6;
    this.slots[2].slotMesh.position.z = 0.27;
    this.slots[2].slotMesh.position.x = 0.2;
  }

  setHands(rightHand: Item, leftHand: Item): void {
    if (this.handsIntersected) this.handsIntersected.isEmpty = true;
    if (rightHand && this.controllerRight?.grip) {
      const newRightHand = rightHand;
      newRightHand.mesh.meshes[0].parent = null;
      newRightHand.mesh.meshes[0].parent = this.rootMeshRight;
      newRightHand.mesh.meshes[0].isPickable = false;
      newRightHand.mesh.meshes[1].isPickable = false;
      newRightHand.mesh.meshes[0].position = new Vector3(0, 0, 0);
      this.hands.push(newRightHand);
    }
    if (leftHand && this.controllerLeft?.grip) {
      const newLeftHand = leftHand;
      newLeftHand.mesh.meshes[0].parent = null;
      newLeftHand.mesh.meshes[0].parent = this.rootMeshLeft;
      newLeftHand.mesh.meshes[0].isPickable = false;
      newLeftHand.mesh.meshes[1].isPickable = false;
      newLeftHand.mesh.meshes[0].position = new Vector3(0, 0, 0);
      this.hands.push(newLeftHand);
    }
  }

  addEvtChangeHands(hand: WebXRInputSource, slots: Slot[]): void {
    const handMesh: AbstractMesh = hand.grip!;
    if (!this.highlight) {
      this.highlight = new HighlightLayer('hl1', this.scene);
    }
    if (!handMesh?.actionManager) {
      // eslint-disable-next-line no-param-reassign
      handMesh.actionManager = new ActionManager(this.scene);
    }
    slots.forEach((slot) => {
      if (slot.item.type === 'tool') {
        handMesh.actionManager!.registerAction(
          new ExecuteCodeAction(
            {
              trigger: ActionManager.OnIntersectionEnterTrigger,
              parameter: {
                mesh: slot.slotMesh,
              },
            },
            () => {
              if (slot.isEmpty) return;
              this.handsIntersected = slot;
              this.highlight.addMesh(
                slot.item.mesh.meshes[1] as Mesh,
                Color3.Green()
              );
            }
          )
        );
        handMesh.actionManager!.registerAction(
          new ExecuteCodeAction(
            {
              trigger: ActionManager.OnIntersectionExitTrigger,
              parameter: {
                mesh: slot.slotMesh,
              },
            },
            () => {
              this.handsIntersected = null;
              this.highlight.removeMesh(slot.item.mesh.meshes[1] as Mesh);
            }
          )
        );
      }
    });
  }

  setController(): void {
    const xrIds: string[] =
      this.controllerRight.motionController!.getComponentIds();
    const triggerComponent =
      this.controllerRight.motionController!.getComponent(xrIds[0]); // xr-standard-trigger
    const triggerComponentLeft =
      this.controllerLeft.motionController!.getComponent(xrIds[0]); // xr-standard-trigger
    triggerComponentLeft.onButtonStateChangedObservable.add(() => {
      if (
        triggerComponentLeft.pressed &&
        (this.handsIntersected?.item.name === 'leftGloves' ||
          this.handsIntersected?.item.name === 'rightGloves')
      ) {
        this.changeTool(['leftGloves', 'rightGloves']);
        this.setHands(this.allItems.rightGloves, this.allItems.leftGloves);
        this.allItems.leftGloves.mesh.meshes[0].setEnabled(true);
        lessonStore.tools.gloves.setSelected(true);
      }
      if (
        triggerComponentLeft.pressed &&
        this.handsIntersected?.item.name === 'tablet'
      ) {
        this.changeTool(['tablet', 'rightGloves']);
        this.setHands(this.allItems.rightGloves, this.allItems.tablet);
        // показываем руку в модельке планшета когда берём планшет
        this.allItems.tablet.mesh.meshes[2].isVisible = true;
        lessonStore.tools.tablet.setSelected(true);
      }
      if (
        triggerComponentLeft.pressed &&
        this.handsIntersected?.item.name === 'multimeter'
      ) {
        this.changeTool(['multimeter', 'multimeterLeft']);
        // показываем руку в модельке планшета когда берём планшет
        this.allItems.multimeter.mesh.meshes[8].isVisible = true;
        this.allItems.multimeterLeft.mesh.meshes[0].setEnabled(true);
        this.setHands(this.allItems.multimeter, this.allItems.multimeterLeft);
        lessonStore.tools.multimeter.setSelected(true);
      }
    });
    triggerComponent.onButtonStateChangedObservable.add(() => {
      if (
        triggerComponent.pressed &&
        (this.handsIntersected?.item.name === 'leftGloves' ||
          this.handsIntersected?.item.name === 'rightGloves')
      ) {
        this.changeTool(['leftGloves', 'rightGloves']);
        this.setHands(this.allItems.rightGloves, this.allItems.leftGloves);
        this.allItems.leftGloves.mesh.meshes[0].setEnabled(true);
        lessonStore.tools.gloves.setSelected(true);
      }
      if (
        triggerComponent.pressed &&
        this.handsIntersected?.item.name === 'tablet'
      ) {
        this.changeTool(['tablet', 'rightGloves']);
        this.setHands(this.allItems.rightGloves, this.allItems.tablet);
        // показываем руку в модельке планшета когда берём планшет
        this.allItems.tablet.mesh.meshes[2].isVisible = true;
        lessonStore.tools.tablet.setSelected(true);
      }
      if (
        triggerComponent.pressed &&
        this.handsIntersected?.item.name === 'multimeter'
      ) {
        this.changeTool(['multimeter', 'multimeterLeft']);
        // показываем руку в модельке планшета когда берём планшет
        this.allItems.multimeter.mesh.meshes[8].isVisible = true;
        this.allItems.multimeterLeft.mesh.meshes[0].setEnabled(true);
        this.setHands(this.allItems.multimeter, this.allItems.multimeterLeft);
        lessonStore.tools.multimeter.setSelected(true);
      }
    });
  }

  changeTool(currentTool: BeltKeys[]): void {
    // удаление дефолтных рук без перчаток
    this.allItems?.leftHand.mesh.meshes[0].dispose();
    this.allItems?.rightHand.mesh.meshes[0].dispose();
    this.hands.forEach((tool) => {
      const slot: Slot | undefined = this.slots.find(
        (i) => i.item.name === tool.name
      );
      // возвращаем инструмент в ячейку инвентаря
      if (slot) {
        // eslint-disable-next-line no-param-reassign
        tool.mesh.meshes[0].parent = slot.slotMesh;
        if (tool.binding) {
          this.allItems[tool.binding].mesh.meshes[0].parent = slot.slotMesh;
        }
        slot.isEmpty = false;
      }
      switch (tool.name) {
        case 'multimeter':
          this.allItems.multimeter.mesh.meshes[8].isVisible = false;
          this.allItems.multimeterLeft.mesh.meshes[0].setEnabled(false);
          break;
        case 'tablet':
          this.allItems.tablet.mesh.meshes[2].isVisible = false;
          break;
        case 'rightGloves':
        case 'leftGloves':
          this.allItems.leftGloves.mesh.meshes[0].setEnabled(false);
          break;
      }
    });
    this.hands = [];
  }
}
