import { PhotoDome, Scene } from '@babylonjs/core';
import { SkyboxBuildConfig } from '../types';

export class SkyboxModel {
  private _main: PhotoDome;

  constructor(scene: Scene, main: PhotoDome) {
    this._main = main;
  }

  static load(cfg: SkyboxBuildConfig, scene: Scene): Promise<SkyboxModel> {
    return new Promise<SkyboxModel>((resolve, reject) => {
      const mainMesh = new PhotoDome(
        'skybox',
        cfg.skybox_url,
        {
          resolution: 16,
          size: 1000,
        },
        scene,
        (msg) => reject(msg)
      );

      if (mainMesh.texture.isReady()) resolve(new SkyboxModel(scene, mainMesh));
      else
        mainMesh.texture.onLoadObservable.add(() =>
          resolve(new SkyboxModel(scene, mainMesh))
        );
    });
  }

  get main(): PhotoDome {
    return this._main;
  }
}
