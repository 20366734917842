import { AdvancedDynamicTexture, Vector2WithInfo } from '@babylonjs/gui';
import {
  Color3,
  Observable,
  Scene,
  StandardMaterial,
  Texture,
} from '@babylonjs/core';
import { autorun } from 'mobx';

import { ISceneObject } from 'features/lesson-page/types';

import { TabletModalWindowBuildConfig } from '../types';
import { TabletModalWindowGUI } from '../gui';
import { TabletModalWindowModel } from '../model';
import { TabletModalWindowStore } from '../store';

export class TabletModalWindowObject implements ISceneObject {
  private static WIDTH = 180;
  private static HEIGHT = 120;
  private static TEX_Q = 3;
  private static REAL_SCALE = 0.135 / 180;

  private _model: TabletModalWindowModel;
  private _texture: AdvancedDynamicTexture;
  private _gui: TabletModalWindowGUI;

  public onCloseButtonClick = new Observable<Vector2WithInfo>();
  public onYesButtonClick = new Observable<Vector2WithInfo>();
  public onNoButtonClick = new Observable<Vector2WithInfo>();

  get model(): TabletModalWindowModel {
    return this._model;
  }

  constructor(scene: Scene, model: TabletModalWindowModel) {
    this._model = model;
    this._texture = new AdvancedDynamicTexture(
      'TabletModalWindow',
      TabletModalWindowObject.WIDTH * TabletModalWindowObject.TEX_Q,
      TabletModalWindowObject.HEIGHT * TabletModalWindowObject.TEX_Q,
      scene,
      false,
      Texture.TRILINEAR_SAMPLINGMODE,
      true
    );
    this._gui = new TabletModalWindowGUI(
      this._texture,
      TabletModalWindowObject.TEX_Q
    );

    // Настройка материала окна
    const modalMat = new StandardMaterial('TabletModal_material', scene);
    modalMat.backFaceCulling = false;
    modalMat.diffuseColor = Color3.Black();
    modalMat.specularColor = Color3.Black();
    modalMat.emissiveTexture = this._texture;
    modalMat.opacityTexture = this._texture;
    this._model.plane.material = modalMat;
    this._texture.attachToMesh(this._model.plane, true);

    // Перенаправление callback'ов
    this._gui.onCloseButtonClickCallback = (e: Vector2WithInfo) => {
      this.onCloseButtonClick.notifyObservers(e);
    };
    this._gui.onYesButtonClickCallback = (e: Vector2WithInfo) => {
      this.onYesButtonClick.notifyObservers(e);
    };
    this._gui.onNoButtonClickCallback = (e: Vector2WithInfo) => {
      this.onNoButtonClick.notifyObservers(e);
    };
  }

  public static async setup(
    cfg: TabletModalWindowBuildConfig,
    scene: Scene
  ): Promise<TabletModalWindowObject> {
    const model = await TabletModalWindowModel.load(
      {
        height:
          TabletModalWindowObject.HEIGHT * TabletModalWindowObject.REAL_SCALE,
        width:
          TabletModalWindowObject.WIDTH * TabletModalWindowObject.REAL_SCALE,
      },
      scene
    );
    return new TabletModalWindowObject(scene, model);
  }

  /**
   * Подключить объект к его стору
   */
  public connectToStore(store: TabletModalWindowStore): void {
    autorun(() => {
      this.setVisibility(store.isVisible);
    });
  }

  public setVisibility(isVisible: boolean): void {
    this._model.setVisibility(isVisible);
  }
}
