import { LessonStepReportModel, StepReportModel } from 'api';
import { ToolsStoreBase } from './scene-components/objects/tools/_base_';

export interface Tool {
  image: string;
  store: ToolsStoreBase;
  handler: () => void;
}

export enum LessonExistenceType {
  Unknown = 'loading',
  Taken = 'taken',
  Available = 'available',
  Unavailable = 'notavailable',
}

export enum SceneLoadingProgressType {
  NotLoaded = 'notloaded',
  InProgress = 'inprogress',
  Loaded = 'loaded',
}

/**
 * Интерфейс, который должны имплементить все объекты сцены
 */
export type ISceneObject = IControlVisibility;

/**
 * Позволяет управлять видимостью объектов. Можно либо показать, либо скрыть объект
 */
export interface IControlVisibility {
  setVisibility(isVisible: boolean): void;
}

/* 
  Тип для описания записей в попап-таблице Админ -> Результаты -> Конкретная запись -> Шаги
*/
export interface PreparedDataItemStepReportModel extends StepReportModel {
  stepDurationFormatted: string;
  errorCountFormatted: string;
}
/* 
  Тип для описания записей в попап-таблице Админ -> Результаты -> Конкретная запись
*/
export interface PreparedDataItemUserStepReport extends LessonStepReportModel {
  fullName: string;
  lessonDurationFormatted: string;
  lessonTypeFormatted: string;
  stepsFormatted: PreparedDataItemStepReportModel[];
}
