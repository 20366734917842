import {
  AbstractMesh,
  ActionManager,
  Color3,
  ExecuteCodeAction,
  HighlightLayer,
  Observable,
  PBRMaterial,
  Scene,
  Space,
  StandardMaterial,
  Texture,
  Vector3,
} from '@babylonjs/core';
import { AdvancedDynamicTexture } from '@babylonjs/gui';
import { autorun } from 'mobx';

import { ISceneObject } from 'features/lesson-page/types';

import { KIPBuildConfig } from '../types';
import { KIPDisplayGUI } from '../gui';
import { KIPDoorController } from './door-cotroller';
import { KIPModel } from '../model';
import { KIPStore } from '../store';

import { CommonPlaneModel } from '../../../common/models';

export type KIPObjectTerminalAction = {
  terminal_index: number;
  controller: number;
};

export class KIPObject implements ISceneObject {
  private static TEX_Q = 4;

  private _model: KIPModel;
  private _displayModel: CommonPlaneModel;
  private _texture: AdvancedDynamicTexture;
  private _gui: KIPDisplayGUI;

  private _isVisible = true;
  private _isDisplayVisible = false;

  private _highlightLayer: HighlightLayer;
  private _backDoor: KIPDoorController;
  private _frontDoor: KIPDoorController;

  private _terminalsDefaultMat: PBRMaterial;
  private _terminalsHighlightMat: PBRMaterial;

  public onClickTerminal = new Observable<KIPObjectTerminalAction>();
  public onIntersectionExitTerminal = new Observable<KIPObjectTerminalAction>();
  public onIntersectionEnterTerminal =
    new Observable<KIPObjectTerminalAction>();

  /**
   * Модель KIP'а
   */
  get model(): KIPModel {
    return this._model;
  }

  get backDoor(): KIPDoorController {
    return this._backDoor;
  }

  get frontDoor(): KIPDoorController {
    return this._frontDoor;
  }

  constructor(scene: Scene, model: KIPModel, displayModel: CommonPlaneModel) {
    this._model = model;
    this._displayModel = displayModel;
    this._texture = new AdvancedDynamicTexture(
      'KIPDisplay',
      100 * KIPObject.TEX_Q,
      100 * KIPObject.TEX_Q,
      scene,
      false,
      Texture.TRILINEAR_SAMPLINGMODE,
      true
    );
    this._gui = new KIPDisplayGUI(this._texture, KIPObject.TEX_Q);

    this._displayModel.root.parent = this._model.root;
    this._displayModel.root.position.set(0, 0.8, 0.25);
    this._displayModel.root.rotate(
      new Vector3(0, 1, 0),
      Math.PI / 2,
      Space.LOCAL
    );

    this._highlightLayer = new HighlightLayer('KIP_hll', scene);
    this._backDoor = new KIPDoorController(
      scene,
      this._highlightLayer,
      model.backDoor,
      model.openBackAnim
    );
    this._frontDoor = new KIPDoorController(
      scene,
      this._highlightLayer,
      model.frontDoor,
      model.openFrontAnim
    );

    this._terminalsDefaultMat = this.model.measureTerminals[0]
      .material as PBRMaterial;
    this._terminalsHighlightMat =
      this._terminalsDefaultMat.clone('HighlightMaterial');
    this._terminalsHighlightMat.emissiveColor.set(0.5, 0.5, 0);

    // Настройка материала дисплея KIP'а
    const displayMat = new StandardMaterial('KIPDisplay_material', scene);
    displayMat.backFaceCulling = false;
    displayMat.specularColor = Color3.Black();
    displayMat.diffuseTexture = this._texture;
    displayMat.opacityTexture = this._texture;
    this._displayModel.plane.material = displayMat;

    this._updateVisibility();

    // Регистрация на событие нажатия на клемму
    this._model.terminals.forEach((t, idx: number) => {
      const terminal = t;
      const notifyValue = { terminal_index: idx, controller: -1 };
      terminal.actionManager = new ActionManager(scene);
      terminal.actionManager.registerAction(
        new ExecuteCodeAction(ActionManager.OnPickTrigger, () => {
          this.onClickTerminal.notifyObservers(notifyValue);
        })
      );
    });
  }

  /**
   * Скачать все модели и создать планшет
   */
  public static async setup(
    cfg: KIPBuildConfig,
    scene: Scene
  ): Promise<KIPObject> {
    const model = await KIPModel.load(cfg, scene);
    const plane = await CommonPlaneModel.load(
      { height: 0.2, width: 0.2 },
      scene
    );
    return new KIPObject(scene, model, plane);
  }

  /**
   * Подключить объект к его стору
   */
  public connectToStore(store: KIPStore): void {
    // Реакции на изменения store задней двери
    autorun(() => {
      this.backDoor.highlight(store.backDoor.isHighlighted);
      this.backDoor.setDoorState(store.backDoor.targetIsOpen);
    });

    // Реакции на изменения store передней двери
    autorun(() => {
      this.frontDoor.highlight(store.frontDoor.isHighlighted);
      this.frontDoor.setDoorState(store.frontDoor.targetIsOpen);
    });

    // Реакции на изменения текста на экране
    autorun(() => {
      this.setDisplayText(store.displayText);
    });

    // Реакции на изменения подсвеченных клемм
    autorun(() => {
      const isHighlighted = store.terminals.highlighted;
      for (let i = 0; i < isHighlighted.length; i += 1)
        this.setTerminalHighlight(i, isHighlighted[i]);
    });

    // Изменение store
    const storeToUpdate = store;
    this.backDoor.onAnimationDone.add((isOpen) => {
      storeToUpdate.backDoor.isOpen = isOpen;
    });
    this.frontDoor.onAnimationDone.add((isOpen) => {
      storeToUpdate.frontDoor.isOpen = isOpen;
    });
  }

  /**
   * Установить hook на событие пересечения терминалов с клеммами
   *
   * Метод не очень продуман, нужно подумать как будет осуществляться переключение на VR
   */
  public setupTerminalIntersectionHook(
    controller: number,
    mesh: AbstractMesh
  ): void {
    this._model.terminals.forEach((t, idx: number) => {
      const terminal = t;

      const actionParams = {
        mesh,
        usePreciseIntersection: true,
      };
      const notifyValue = { terminal_index: idx, controller };
      terminal.actionManager!.registerAction(
        new ExecuteCodeAction(
          {
            trigger: ActionManager.OnIntersectionEnterTrigger,
            parameter: actionParams,
          },
          () => {
            this.onIntersectionEnterTerminal.notifyObservers(notifyValue);
          }
        )
      );
      terminal.actionManager!.registerAction(
        new ExecuteCodeAction(
          {
            trigger: ActionManager.OnIntersectionExitTrigger,
            parameter: actionParams,
          },
          () => {
            this.onIntersectionExitTerminal.notifyObservers(notifyValue);
          }
        )
      );
    });
  }

  /**
   * Установить текст на дисплей KIP'а
   */
  public setDisplayText(text: string): void {
    this._gui.textBlock.text = text;
  }

  public setTerminalHighlight(index: number, isHighlighted: boolean): void {
    this._model.terminals[index].material = isHighlighted
      ? this._terminalsHighlightMat
      : this._terminalsDefaultMat;
  }

  private _updateVisibility(): void {
    this._model.setVisibility(this._isVisible);
    this._displayModel.setVisibility(this._isVisible && this._isDisplayVisible);
  }

  /**
   * Изменить видимость KIP
   */
  public setVisibility(isVisible: boolean): void {
    this._isVisible = isVisible;
    this._updateVisibility();
  }

  /**
   * Изменить видимость экрана KIP
   */
  public setDisplayVisibility(isVisible: boolean): void {
    this._isDisplayVisible = isVisible;
    this._updateVisibility();
  }
}
