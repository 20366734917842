import { Button, Container, Control, Measure, TextBlock } from '@babylonjs/gui';

export class WBodyAskAcceptVolt extends Container {
  private _LABEL_PADDING = 10;
  private _LABEL_HEIGHT = 45;
  private _LABEL_FONT_SIZE = 13;
  private _BUTTON_PADDING = 10;
  private _BUTTON_HEIGHT = 25;
  private _BUTTON_FONT_SIZE = 13;
  private _BUTTON_RADIUS = 4;

  public labelText: TextBlock;
  public yesButton: Button;
  public noButton: Button;

  private _scale: number;

  public getScale(): number {
    return this._scale;
  }

  public setScale(scale: number): void {
    this._scale = scale;

    this._markAsDirty();
  }

  /**
   * Создание текстуры планшета
   * @param scene объект сцены
   * @param name имя, используемое для создания виджетов
   */
  constructor(scale: number) {
    super();
    this._scale = scale;
    this.labelText = this._setupAskText();
    this.yesButton = this._setupYesButton();
    this.noButton = this._setupNoButton();
  }

  /**
   * Создание и настройка текста вопроса
   */
  private _setupAskText(): TextBlock {
    const text = new TextBlock('AskAcceptVolt_titleText');
    text.fontFamily = 'sans-serif';
    text.text = 'Напряжение в пределах допустимого?';
    text.color = '#222';
    text.textWrapping = true;
    text.textHorizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    text.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    text.horizontalAlignment = Container.HORIZONTAL_ALIGNMENT_CENTER;
    this.addControl(text);
    return text;
  }

  // TODO: вынести в отдельный класс
  private static createBlueButton(name: string, text: string): Button {
    const btn = Button.CreateSimpleButton(name, text);
    btn.fontFamily = 'sans-serif';
    btn.verticalAlignment = Control.VERTICAL_ALIGNMENT_BOTTOM;
    btn.color = 'white';
    btn.background = '#1c90f5';
    btn.thickness = 0;
    return btn;
  }

  /**
   * Создание и настройка кнопки "Да"
   */
  private _setupYesButton(): Button {
    const btn = WBodyAskAcceptVolt.createBlueButton(
      'AskAcceptVolt_yesButton',
      'Да'
    );
    btn.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    this.addControl(btn);
    return btn;
  }

  /**
   * Создание и настройка кнопки "Нет"
   */
  private _setupNoButton(): Button {
    const btn = WBodyAskAcceptVolt.createBlueButton(
      'AskAcceptVolt_noButton',
      'Нет'
    );
    btn.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_RIGHT;
    this.addControl(btn);
    return btn;
  }

  protected _additionalProcessing(
    parentMeasure: Measure,
    context: CanvasRenderingContext2D
  ): void {
    super._additionalProcessing(parentMeasure, context);

    const box = this._currentMeasure;
    const scale = this._scale;

    this.labelText.fontSize = this._LABEL_FONT_SIZE * scale;
    this.labelText.top = this._LABEL_PADDING * scale;
    this.labelText.heightInPixels = this._LABEL_HEIGHT * scale;
    this.labelText.widthInPixels = box.width - this._LABEL_PADDING * 2 * scale;

    const buttonWidth = (box.width - this._BUTTON_PADDING * scale * 3) / 2;

    this.yesButton.fontSize = this._BUTTON_FONT_SIZE * scale;
    this.yesButton.top = -this._BUTTON_PADDING * scale;
    this.yesButton.left = this._BUTTON_PADDING * scale;
    this.yesButton.heightInPixels = this._BUTTON_HEIGHT * scale;
    this.yesButton.widthInPixels = buttonWidth;
    this.yesButton.cornerRadius = this._BUTTON_RADIUS * scale;

    this.noButton.fontSize = this._BUTTON_FONT_SIZE * scale;
    this.noButton.top = -this._BUTTON_PADDING * scale;
    this.noButton.left = -this._BUTTON_PADDING * scale;
    this.noButton.heightInPixels = this._BUTTON_HEIGHT * scale;
    this.noButton.widthInPixels = buttonWidth;
    this.noButton.cornerRadius = this._BUTTON_RADIUS * scale;
  }
}
