import { autorun } from 'mobx';
// eslint-disable-next-line import/no-cycle
import { LessonStoreInstance as lessonStore } from 'features/lesson-page/store';

import { KIPObjectTerminalAction } from '../../objects/world/kip/object';
import { LessonScene } from '../scene';
import { SceneBuilderLogic } from '../types';

/**
 * Установка логики KIP
 */
export function setupLogicForWorldKIP(
  scene: LessonScene,
  cfg: SceneBuilderLogic
): void {
  if (!scene.world.kip) return;
  const objStore = lessonStore.world.kip;
  const obj = scene.world.kip;

  // Реакции на действия пользователя с задней дверью
  obj.backDoor.onPointerOver.add(() => {
    objStore.backDoor.isHighlighted = true;
  });
  obj.backDoor.onPointerOut.add(() => {
    objStore.backDoor.isHighlighted = false;
  });
  obj.backDoor.onPointerClick.add(() => {
    objStore.backDoor.toggleOpenState();
  });

  // Реакции на действия пользователя с передней дверью
  obj.frontDoor.onPointerOver.add(() => {
    objStore.frontDoor.isHighlighted = true;
  });
  obj.frontDoor.onPointerOut.add(() => {
    objStore.frontDoor.isHighlighted = false;
  });
  obj.frontDoor.onPointerClick.add(() => {
    objStore.frontDoor.toggleOpenState();
  });

  // Установить посветку выделенных мультиметром клемм
  autorun(() => {
    objStore.terminals.setHighlightedAll(false);
    for (const index of lessonStore.tools.multimeter.probes)
      objStore.terminals.setHighlighted(index, true);
  });

  const { multimeter } = lessonStore.tools;

  // Обновить текст на экране КИП
  autorun(() => {
    if (!lessonStore.isVR) {
      objStore.setDisplayText('');
      return;
    }
    if (multimeter.selected) objStore.setDisplayText(multimeter.screenText);
    else objStore.setDisplayText('');
  });

  const makeMeasure = () => {
    const possibleRange = { min: 0, max: 5 };
    const multimeterValue =
      possibleRange.min +
      Math.random() * (possibleRange.max - possibleRange.min);
    multimeter.setVoltage(multimeterValue);
  };

  const clickTerminalCallback = (action: KIPObjectTerminalAction) => {
    if (!lessonStore.tools.multimeter.selected) return;
    const idx = action.terminal_index;

    const isActive = multimeter.probes.includes(idx);
    if (isActive) multimeter.disconnectProbe(idx);
    else if (multimeter.probes.length < 2) {
      multimeter.connectProbe(idx);
      if (multimeter.probes.length === 2) makeMeasure();
    }
  };

  // Реакция на нажатие на терминал
  obj.onClickTerminal.add(clickTerminalCallback);

  // Реакция на персечение мультиметра с клеммой
  obj.onIntersectionEnterTerminal.add(clickTerminalCallback);
  obj.onIntersectionEnterTerminal.add((action) => {
    const controller =
      lessonStore.player?.multimeterProbes[action.controller].controller;
    controller?.motionController?.pulse(0.5, 0.3);
  });

  // При переключении в VR
  autorun(() => {
    if (!lessonStore.isVR) return;
    lessonStore.player?.multimeterProbes.forEach((probeHand, idx) => {
      scene.world.kip?.setupTerminalIntersectionHook(idx, probeHand.probe);
    });
  });
}
