import { AdvancedDynamicTexture, Vector2WithInfo } from '@babylonjs/gui';
import {
  Color3,
  Observable,
  Scene,
  StandardMaterial,
  Texture,
} from '@babylonjs/core';
import { autorun } from 'mobx';

import { ISceneObject } from 'features/lesson-page/types';

import { TabletBuildConfig } from '../types';
import { TabletModel } from '../model';
import { TabletRecord, TabletStore } from '../store';
import { TabletSheetGUI } from '../gui';

export class TabletObject implements ISceneObject {
  private static TEX_Q = 3;

  private _model: TabletModel;
  private _texture: AdvancedDynamicTexture;
  private _gui: TabletSheetGUI;

  /**
   * Вызывается когда кнопка 'Записать значения' нажата
   */
  public onAddButtonClick = new Observable<Vector2WithInfo>();

  /**
   * Получить модель планшета
   */
  public get model(): TabletModel {
    return this._model;
  }

  constructor(scene: Scene, model: TabletModel) {
    this._model = model;
    this._texture = new AdvancedDynamicTexture(
      'Tablet',
      210 * TabletObject.TEX_Q,
      297 * TabletObject.TEX_Q,
      scene,
      false,
      Texture.TRILINEAR_SAMPLINGMODE,
      true
    );
    this._texture.background = 'white';
    this._gui = new TabletSheetGUI(this._texture, TabletObject.TEX_Q);

    // Настройка материала листа планшета
    const sheetMat = new StandardMaterial('TabletSheet_material', scene);
    sheetMat.backFaceCulling = false;
    sheetMat.specularColor = Color3.Black();
    sheetMat.diffuseTexture = this._texture;
    this._model.sheetMesh.material = sheetMat;
    this._texture.attachToMesh(this._model.sheetMesh, true);

    // Перенаправление callback'ов
    this._gui.onAddButtonClickCallback = (e: Vector2WithInfo) => {
      this.onAddButtonClick.notifyObservers(e);
    };
  }

  /**
   * Скачать все модели и создать планшет
   */
  public static async setup(
    cfg: TabletBuildConfig,
    scene: Scene
  ): Promise<TabletObject> {
    const model = await TabletModel.load(cfg, scene);
    return new TabletObject(scene, model);
  }

  /**
   * Подключить объект к его стору
   */
  public connectToStore(store: TabletStore): void {
    autorun(() => {
      this.setVisibility(store.selected);
      this.setEnable(!store.isEnabled);
    });
    autorun(() => {
      this.setTabletContent(store.records);
    });
  }

  /**
   * Активен ли планшет (работают ли кнопки на планшете)
   */
  public setEnable(isEnable: boolean): void {
    this._gui.isEnabled = isEnable;
  }

  /**
   * Обновить все записи в таблице
   */
  public setTabletContent(records: TabletRecord[]): void {
    this._gui.setTabletContent(records);
  }

  /**
   * Изменить видимость планшета
   */
  public setVisibility(isVisible: boolean): void {
    this._model.setVisibility(isVisible);
  }
}
