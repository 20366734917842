import { observer } from 'mobx-react';
import { useRootData } from 'hooks';
import React, { FC, useMemo } from 'react';
import classnames from 'classnames';

import { Tool } from '../../../../types';

import css from './index.module.css';

// ToDo: get absolute url from script.json file
const url = 'https://fs.devogip.ru/api/File/minio-dev/';

const ToolBar: FC = observer(() => {
  const { toolsStore } = useRootData((store) => ({
    toolsStore: store.lesson.tools,
  }));

  const tools: Record<string, Tool> = useMemo(
    () => ({
      gloves: {
        image: 'gloves_icon_2.png',
        store: toolsStore.gloves,
        handler: () => {
          toolsStore.gloves.toggleSelected();
        },
      },
      tablet: {
        image: 'tablet_icon_2.png',
        store: toolsStore.tablet,
        handler: () => {
          toolsStore.tablet.toggleSelected();
          toolsStore.multimeter.setSelected(false);
        },
      },
      multimeter: {
        image: 'multimeter_icon_2.png',
        store: toolsStore.multimeter,
        handler: () => {
          toolsStore.multimeter.toggleSelected();
          toolsStore.tablet.setSelected(false);
        },
      },
    }),
    [toolsStore.gloves, toolsStore.multimeter, toolsStore.tablet]
  );

  return (
    <div className={css.toolBarWrapper}>
      {Object.keys(tools).map((itemKey: string) => (
        <button
          key={itemKey}
          className={classnames(css.iconButton, {
            [css.iconButtonSelected]: tools[itemKey].store.selected,
          })}
          // eslint-disable-next-line react/jsx-handler-names
          onClick={tools[itemKey].handler}
          type="button"
        >
          <img
            alt={itemKey}
            className={classnames(css.toolWrapper, {
              [css.toolWrapperSelected]: tools[itemKey].store.selected,
            })}
            src={`${url}${tools[itemKey].image}`}
          />
        </button>
      ))}
    </div>
  );
});

export default ToolBar;
