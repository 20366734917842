import { AbstractMesh, LoadFileError, Scene } from '@babylonjs/core';

import { ISimpleModel } from '../../../common/types';
import { loadMesh } from '../../../common/utils';

import { LandscapeBuildConfig } from '../types';

export class LandscapeModel implements ISimpleModel {
  private _allMeshes: AbstractMesh[];
  private _root: AbstractMesh;
  private _main: AbstractMesh;

  constructor(scene: Scene, allMeshes: AbstractMesh[]) {
    this._allMeshes = allMeshes;
    [this._root, this._main] = allMeshes;
  }

  static async load(
    cfg: LandscapeBuildConfig,
    scene: Scene
  ): Promise<LandscapeModel> {
    const res = await loadMesh(cfg.model, scene);
    if (
      !res ||
      res.meshes.length !== 4 ||
      !res.meshes.every((e) => e !== null)
    ) {
      throw new LoadFileError('Unable to load mesh for LandscapeModel');
    }

    return new LandscapeModel(scene, res.meshes);
  }

  /**
   * Управлять глобальной видимостью объекта
   */
  setVisibility(isVisible: boolean): void {
    for (const m of this._allMeshes) m.isVisible = isVisible;
  }

  /**
   * Получить корень, за который можно привязывать модель
   */
  get root(): AbstractMesh {
    return this._root;
  }

  get main(): AbstractMesh {
    return this._main;
  }

  get allMeshes(): AbstractMesh[] {
    return this._allMeshes;
  }
}
