import { autorun } from 'mobx';
// eslint-disable-next-line import/no-cycle
import { LessonStoreInstance as lessonStore } from 'features/lesson-page/store';

import { LessonScene } from '../scene';
import { SceneBuilderLogic } from '../types';

/**
 * Установка логики по умолчанию для планшета
 * @param obj инстанция планшета
 */
export function setupLogicForToolsTablet(
  scene: LessonScene,
  cfg: SceneBuilderLogic
): void {
  if (!scene.tools.tablet) return;
  const objStore = lessonStore.tools.tablet;
  const obj = scene.tools.tablet;

  autorun(() => {
    objStore.setEnabled(lessonStore.world.tabletModalWindow.isVisible);
  });

  // Действие при нажатии на кнопку "Записать значения"
  obj.onAddButtonClick.add(() => {
    const selectedTerminals = lessonStore.tools.multimeter.probes;
    const { voltage } = lessonStore.tools.multimeter;

    // не записывать в планшет, если выбрано менее двух клемм
    if (selectedTerminals.length < 2) return;

    const terminals = lessonStore.lessonScene?.world.kip?.model.terminals;
    if (!terminals) return;

    const term1Name = terminals[selectedTerminals[0]].name;
    const term2Name = terminals[selectedTerminals[1]].name;

    if (cfg.askUserIfVoltageCorrect)
      objStore.addRecord(term1Name, term2Name, voltage);
    // Just add the correct answer
    else objStore.addRecord(term1Name, term2Name, voltage, true);

    if (cfg.askUserIfVoltageCorrect) {
      // Спрашиваем пользователя находится ли значение в допустимом диапазоне
      lessonStore.world.tabletModalWindow.setVisibility(true);
    } else if (!cfg.allowAddingSameMeasureSeveralTimes) {
      // Обнуляем, чтобы не записывалось дважды
      lessonStore.tools.multimeter.disconnectAllProbes();
    }
  });
}
