import { AdvancedDynamicTexture, TextBlock } from '@babylonjs/gui';

export class MultimeterScreenGUI {
  private textBlockParams = {
    fontFamily: 'digital-clock-font',
    color: '#000',
    fontSize: 30,
  };

  private _textblockValue: TextBlock;

  constructor(texture: AdvancedDynamicTexture, scale: number) {
    this._textblockValue = new TextBlock();
    this._textblockValue.fontFamily = this.textBlockParams.fontFamily;
    this._textblockValue.color = this.textBlockParams.color;
    this._textblockValue.fontSize = this.textBlockParams.fontSize * scale;
    texture.addControl(this._textblockValue);
  }

  set textblockValue(value: string) {
    this._textblockValue.text = value;
  }
}
