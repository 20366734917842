import { autorun } from 'mobx';
// eslint-disable-next-line import/no-cycle
import { LessonStoreInstance as lessonStore } from 'features/lesson-page/store';

import { LessonScene } from '../scene';
import { SceneBuilderLogic } from '../types';

/**
 * Установка логики модального окна планшета
 */
export function setupLogicForWorldTabletModalWindow(
  scene: LessonScene,
  cfg: SceneBuilderLogic
): void {
  if (!scene.world.tabletModalWindow) return;
  const obj = scene.world.tabletModalWindow;
  const objStore = lessonStore.world.tabletModalWindow;
  const tabletStore = lessonStore.tools.tablet;

  autorun(() => {
    obj.setVisibility(tabletStore.selected && objStore.isVisible);
  });

  const closeModalWindow = () => {
    objStore.setVisibility(false); // Закрываем окно
  };

  // Закрывать окно в любом случае
  obj.onCloseButtonClick.add(closeModalWindow);
  obj.onYesButtonClick.add(closeModalWindow);
  obj.onNoButtonClick.add(closeModalWindow);

  // При нажатии на крестик на окне
  obj.onCloseButtonClick.add(() => {
    tabletStore.removeLastRecord();
  });

  // При нажатии на кнопку 'Да' на окне
  obj.onYesButtonClick.add(() => {
    tabletStore.setErrorAcceptableInLastRecord(true);

    if (!cfg.allowAddingSameMeasureSeveralTimes)
      // Обнуляем, чтобы не записывалось дважды
      lessonStore.tools.multimeter.disconnectAllProbes();
  });

  // При нажатии на кнопку 'Нет' на окне
  obj.onNoButtonClick.add(() => {
    tabletStore.setErrorAcceptableInLastRecord(false);

    if (!cfg.allowAddingSameMeasureSeveralTimes)
      // Обнуляем, чтобы не записывалось дважды
      lessonStore.tools.multimeter.disconnectAllProbes();
  });
}
