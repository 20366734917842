import { autorun } from 'mobx';
// eslint-disable-next-line import/no-cycle
import { LessonStoreInstance as lessonStore } from 'features/lesson-page/store';

import { LessonScene } from '../scene';
import { SceneBuilderLogic } from '../types';

/**
 * Установка логики для полноэкранного интерфейса
 */
export function setupLogicForFullscreenPanel(
  scene: LessonScene,
  cfg: SceneBuilderLogic
): void {
  if (!scene.fullscreenPanel) return;
  const obj = scene.fullscreenPanel;
  const objStore = lessonStore.fullscreenPanel;

  // При нажатии на панель
  obj.stepsPanel.onPointerClick.add(() => {
    objStore.stepsPanel.toggleOpen();
  });

  autorun(() => {
    objStore.stepsPanel.activeStepNo = lessonStore.script.currentStepNo;
  });
  autorun(() => {
    objStore.stepsPanel.setSteps(lessonStore.script.allStepsName);
  });
}
