import { action, makeAutoObservable } from 'mobx';

import { KIPDoorStore } from './door';
import { KIPTerminalsStore } from './terminals';

export { KIPDoorStore } from './door';
export { KIPTerminalsStore } from './terminals';

export class KIPStore {
  private _frontDoor = new KIPDoorStore();
  private _backDoor = new KIPDoorStore();
  private _terminals = new KIPTerminalsStore(12 + 4);

  private _displayText = '';

  /**
   * Передняя дверь
   */
  public get frontDoor(): KIPDoorStore {
    return this._frontDoor;
  }

  /**
   * Задняя дверь
   */
  public get backDoor(): KIPDoorStore {
    return this._backDoor;
  }

  /**
   * Клеммы
   */
  public get terminals(): KIPTerminalsStore {
    return this._terminals;
  }

  public get displayText(): string {
    return this._displayText;
  }

  constructor() {
    makeAutoObservable(this, {
      setDisplayText: action,
    });
  }

  public setDisplayText(value: string): void {
    this._displayText = value;
  }
}
