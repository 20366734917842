import { AdvancedDynamicTexture } from '@babylonjs/gui';
import { Scene, StandardMaterial, Texture } from '@babylonjs/core';
import { autorun } from 'mobx';

import { ISceneObject } from 'features/lesson-page/types';
import { MultimeterBuildConfig } from '../types';
import { MultimeterModel } from '../model';
import { MultimeterScreenGUI } from '../gui';
import { MultimeterStore } from '../store';

export class MultimeterObject implements ISceneObject {
  private static WIDTH = 80;
  private static HEIGHT = 60;
  private static TEX_Q = 4;

  private _model: MultimeterModel;
  private _multimeterScreenGui: MultimeterScreenGUI;
  private _texture: AdvancedDynamicTexture;

  constructor(scene: Scene, model: MultimeterModel) {
    this._model = model;
    this._texture = new AdvancedDynamicTexture(
      'ToolsMultimeter',
      MultimeterObject.WIDTH * MultimeterObject.TEX_Q,
      MultimeterObject.HEIGHT * MultimeterObject.TEX_Q,
      scene,
      false,
      Texture.TRILINEAR_SAMPLINGMODE,
      true
    );

    const screenMat = new StandardMaterial('MultimeterScreen_material', scene);
    screenMat.backFaceCulling = false;
    screenMat.diffuseTexture = this._texture;
    screenMat.opacityTexture = this._texture;
    this._model.setModalPlaneMaterial(screenMat);

    this._multimeterScreenGui = new MultimeterScreenGUI(
      this._texture,
      MultimeterObject.TEX_Q
    );
  }

  get model(): MultimeterModel {
    return this._model;
  }

  public static async setup(
    cfg: MultimeterBuildConfig,
    scene: Scene
  ): Promise<MultimeterObject> {
    const model = await MultimeterModel.load(cfg, scene);

    return new MultimeterObject(scene, model);
  }

  /**
   * Подключить объект к его стору
   */
  public connectToStore(store: MultimeterStore): void {
    autorun(() => {
      this.setMultimeterScreenValue(store.screenText);
    });
    autorun(() => {
      this.setVisibility(store.selected);
    });
  }

  setMultimeterScreenValue(value: string): void {
    this._multimeterScreenGui.textblockValue = value;
  }

  setVisibility(isVisible: boolean): void {
    this._model.setVisibility(isVisible);
  }
}
